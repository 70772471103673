import { IPersonsFilterUI } from "./_types"
import { IconAdd, IconClearClose, IconFilter } from "images/icons"

import { Flex, Grid, ToolTip, Drawer, Divider, Button, Table, Toogle } from "components/ui"


/** Фильтр физических лиц */
export const PersonsFilterUI = ({
    isOpen = false, accessLevelID, 
    filterFacultyList, filterSpecialityList, filterEducationFormList,
    filterEducationLevelList, filterFinanceTypeList, filterCitizenshipList,
    filterIsTargetDirection, filterIsDisabilityGroup, filterIsDead,
    handlerButtonCancel, handlerApplyClearFilter, handlerOpenDictionary, handlerDeleteItemToFilterList,
}: IPersonsFilterUI) => {

    /** Возвращает список факультетов в виде строки */
    const handlerReturnFaculty = () => {
        let facultyListSelected: string = ''
        if (filterFacultyList.value.length > 0) filterFacultyList.value.forEach((item) => { facultyListSelected += `'${item.name}',` })
        return facultyListSelected
    }

    // const handleSelectStatus = (id: number) => {
    //     const updatedStatuses = filterApplicationStatuses.value.map(status => {
    //         if (status.id === id) {
    //             return { ...status, isSelected: !status.isSelected }
    //         }

    //         return status // Возвращаем неизмененный элемент для остальных элементов массива
    //     })

    //     filterApplicationStatuses.setValue(updatedStatuses)
    // }

    // const handleClearSelection = () => {
    //     const updatedStatuses = filterApplicationStatuses.value.map(status => ({ ...status, isSelected: false }))
    //     filterApplicationStatuses.setValue(updatedStatuses)
    // }

    return <>
        <Drawer isOpen={isOpen} zIndex={1001} isEsc isWrapper onCancel={handlerButtonCancel} maxWidth='370px' placement='right'>
            <Flex className='drawer_container'>
                <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                    <header>
                        <Flex gap='4px' direction='row' alignItems='center'>
                            <IconFilter color='#fff' width={20} height={20} />
                            <h4>Фильтр...</h4>
                        </Flex>
                    </header>

                    <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                        <IconClearClose />
                    </Button>
                </Flex>

                <Flex gap='4px' className='drawer_container-body'>
                    <Flex gap='20px' className='drawer_container-content'>
                        <Flex gap="2px" isVisible={accessLevelID === 1}>
                            <Divider prefix='Подразделение/Факультет' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'faculty')}><IconAdd width={18} height={18} /></Button>
                                        {filterFacultyList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterFacultyList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterFacultyList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterFacultyList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('faculty', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Специальность' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'speciality', handlerReturnFaculty())}><IconAdd width={18} height={18} /></Button>
                                        {filterSpecialityList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterSpecialityList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterSpecialityList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterSpecialityList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('speciality', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Форма обучения' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'educationForm', handlerReturnFaculty())}><IconAdd width={18} height={18} /></Button>
                                        {filterEducationFormList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterEducationFormList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterEducationFormList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterEducationFormList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('educationForm', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Уровень образования' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'educationLevel', handlerReturnFaculty())}><IconAdd width={18} height={18} /></Button>
                                        {filterEducationLevelList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterEducationLevelList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterEducationLevelList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterEducationLevelList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('educationLevel', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Тип финансирования' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'financeType', handlerReturnFaculty())}><IconAdd width={18} height={18} /></Button>
                                        {filterFinanceTypeList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterFinanceTypeList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterFinanceTypeList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterFinanceTypeList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('financeType', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Гражданство' 
                                suffix={
                                    <Flex direction="row">
                                        <Button id='tooltipBtnSelect' size="xs" variant="icon" onClick={() => handlerOpenDictionary(true, 'citizenship', handlerReturnFaculty())}><IconAdd width={18} height={18} /></Button>
                                        {filterCitizenshipList?.value.length === 0 ? ''
                                            : <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterCitizenshipList.setValue([]) }><IconClearClose width={18} height={18} /></Button>
                                        }
                                    </Flex>
                                } 
                            />

                            <Flex gap="4px" direction="row" flexWrap="wrap" className="section">
                                {filterCitizenshipList?.value.length === 0 
                                    ? 'Не выбрано'
                                    : filterCitizenshipList?.value.map((item) => (
                                        <Table.Row key={item.name} indexContent='✘' indexContentWidth="25px" paddingInset="2px 6px" onClick={() => handlerDeleteItemToFilterList('citizenship', item.name)}>{item.name}</Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex>

                        <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section">
                            <div>Целевое направление:</div>
                            <Toogle checked={filterIsTargetDirection.value} onChange={(e) => filterIsTargetDirection.setValue(e.target.checked)} />
                        </Flex>

                        <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section">
                            <div>Группа инвалидности:</div>
                            <Toogle checked={filterIsDisabilityGroup.value} onChange={(e) => filterIsDisabilityGroup.setValue(e.target.checked)} />
                        </Flex>

                        <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section">
                            <div>Является умершим:</div>
                            <Toogle checked={filterIsDead.value} onChange={(e) => filterIsDead.setValue(e.target.checked)} />
                        </Flex>

                        {/* <Flex gap="2px">
                            <Divider prefix='Дата отложенного звонка' suffix={(filterDateNextCallFrom.value || filterDateNextCallBefore.value) && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => { filterDateNextCallFrom.setValue(''); filterDateNextCallBefore.setValue('') }}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px" direction="row">
                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='от' width='100%' {...filterDateNextCallFrom}
                                    suffix={<Flex direction="row">
                                        {filterDateNextCallFrom.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateNextCallFrom.setValue('dateNextCallFrom')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateNextCallFrom')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />

                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='по' width='100%' {...filterDateNextCallBefore}
                                    suffix={<Flex direction="row">
                                        {filterDateNextCallBefore.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateNextCallBefore.setValue('dateNextCallBefore')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateNextCallBefore')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Дата начала обучения' suffix={(filterDateStartEducationFrom.value || filterDateStartEducationBefore.value) && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => { filterDateStartEducationFrom.setValue(''); filterDateStartEducationBefore.setValue('') }}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px" direction="row">
                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='от' width='100%' {...filterDateStartEducationFrom}
                                    suffix={<Flex direction="row">
                                        {filterDateStartEducationFrom.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateStartEducationFrom.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateStartEducationFrom')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />

                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='по' width='100%' {...filterDateStartEducationBefore}
                                    suffix={<Flex direction="row">
                                        {filterDateStartEducationBefore.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateStartEducationBefore.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateStartEducationBefore')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Дата создания заявки' suffix={(filterDateCreateFrom.value || filterDateCreateBefore.value) && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => { filterDateCreateFrom.setValue(''); filterDateCreateBefore.setValue('') }}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px" direction="row">
                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='от' width='100%' {...filterDateCreateFrom}
                                    suffix={<Flex direction="row">
                                        {filterDateCreateFrom.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateCreateFrom.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateCreateFrom')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />

                                <Input placeholder='Дата' type='mask' mask='99.99.2099' maskChar="" size='sm' paddingRight={2}
                                    prefix='по' width='100%' {...filterDateCreateBefore}
                                    suffix={<Flex direction="row">
                                        {filterDateCreateBefore.value && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={() => filterDateCreateBefore.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                                        <Button id='tooltipBtnSelect' size='xs' variant='icon' onClick={() => handleOpenModalCalendar(true, 'dateCreateBefore')}><IconDateEdit width={18} height={18} /></Button>
                                    </Flex>}
                                />
                            </Flex>
                        </Flex>

                        <Flex gap="2px">
                            <Divider prefix='Статус заявок' suffix={filterApplicationStatuses.value.filter((item) => item.isSelected === true).length > 0 && <Button id='tooltipBtnClear' size='xs' variant='icon' onClick={handleClearSelection}><IconClearClose width={18} height={18} /></Button>} />

                            <Flex gap="4px">
                                {filterApplicationStatuses.value.length === 0
                                    ? ''
                                    : filterApplicationStatuses.value.map((item) => (
                                        <Table.Row key={item.id} indexContent={`${item.isSelected ? '✔' : item.id}`} backgroundColor={`${item.isSelected ? '#f34e2e' : ''}`} indexContentWidth="30px" paddingInset="4px 8px" onClick={() => handleSelectStatus(item.id)}>
                                            <p>{item.name}</p>
                                        </Table.Row>
                                    ))
                                }
                            </Flex>
                        </Flex> */}
                    </Flex>

                    <Grid gap="5px" gridTemplate="columns" gridTemplateColumns="1fr 1fr">
                        <Button onClick={() => handlerApplyClearFilter('apply')}>Применить</Button>
                        <Button variant="primaryDark" onClick={() => handlerApplyClearFilter('clear')}>Очистить</Button>
                    </Grid>
                </Flex>
            </Flex>

            <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="bottom-end" />
            <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="right" />
            <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="bottom-end" />
        </Drawer>
    </>
}