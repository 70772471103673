import { Navigate } from 'react-router-dom'
import { Route, Routes } from 'react-router'


import { PersonsContainer } from 'pages/persons'
import { CompaniesContainer } from 'pages/companies'

import { Page404 } from 'pages/page404'


/** Маршруты для главной страницы */
const MainRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to='persons' />} />
            <Route path='persons' element={<PersonsContainer />} />
            <Route path='companies' element={<CompaniesContainer />} />

            {/* <Route path='transfers' element={<MessagesContainer />} /> */}
            {/* <Route path='settings' element={<SettingsContainer />} /> */}
            
            <Route key='*' path='*' element={<Page404 />} />
        </Routes>
    )
}

export default MainRoutes