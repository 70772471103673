import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardCompanyUI } from "./_interface"
import { FieldsErrorType, ICardCompanyContainer, ICardCompanyUI, IFieldsError } from "./_types"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"

import { useCompanies } from "store/companies"

// import { fetchSuggestions, SuggestionResponse } from "services/api.dadata.service"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"
import { validFormData } from "hooks/useForm"


/** Анкета контрагента - Контейнер */
export const CardCompanyContainer = ({isOpen, cardMode, cardID, handlerButtonOk, handlerButtonCancel}: ICardCompanyContainer) => {
    const notify = useContext(NotificationContext)

    const loadTitle = useInput<string>('Загрузка данных...')

    const fieldsError = useInput<IFieldsError>({})
    const txtCompanyINN = useInput<string>('')
    const txtCompanyKPP = useInput<string>('')
    const txtCompanyName = useInput<string>('')
    const txtCompanyPhone = useInput<string>('')
    const txtCompanyEmail = useInput<string>('')

    const isVisibleKPP = useInput<boolean>(false)

    /** Хранилище - Контрагенты */
    const { 
        isLoadingCard,
        clearCompanyCard, getCompanyCard, setCompanyCardSave, getFetchSuggestions
    } = useCompanies(useShallow((state) => ({
        isLoadingCard: state.companyCard.isLoading,

        companyINN: state.companyCard.items.companyINN,
        companyKPP: state.companyCard.items.companyKPP,
        companyName: state.companyCard.items.companyName,
        companyPhone: state.companyCard.items.companyPhone,
        companyEmail: state.companyCard.items.companyEmail,

        clearCompanyCard: state.clearCompanyCard,
        getCompanyCard: state.getCompanyCard,
        setCompanyCardSave: state.setCompanyCardSave,
        getFetchSuggestions: state.getFetchSuggestions
    })))
    

    useEffect(() => {
        if (isOpen) {
            if (cardMode === 'edit') {
                getCompanyCard(cardID || '').then((res) => {
                    if (res.status === 200) {
                        txtCompanyINN.setValue(res.data?.companyINN || '')
                        txtCompanyKPP.setValue(res.data?.companyKPP || '')
                        txtCompanyName.setValue(res.data?.companyName || '')
                        txtCompanyPhone.setValue(res.data?.companyPhone || '')
                        txtCompanyEmail.setValue(res.data?.companyEmail || '')
                    }
                })
            }
        } else {
            clearCompanyCard()

            txtCompanyINN.setValue('')
            txtCompanyKPP.setValue('')
            txtCompanyName.setValue('')
            txtCompanyPhone.setValue('')
            txtCompanyEmail.setValue('')

            loadTitle.setValue('Загрузка данных...')
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // Если ИНН ЮЛ, то показываем КПП, иначе скрываем и очищаем КПП
        if (isOpen && txtCompanyINN.value.length === 10) {
            isVisibleKPP.setValue(true)
        } else {
            isVisibleKPP.setValue(false)
            txtCompanyKPP.setValue('')
        }
    }, [isOpen, txtCompanyINN.value]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Поле ИНН контрагента - изменение и запуск проверки */
    const txtCompanyINNOnChange = (value: string) => {
        if (!/^\d+$/.test(value) && value) return

        txtCompanyINN.setValue(value)
        if (value) debounceINN(value)
    }
    /** Проверка контрагента по ИНН */
    const verificationCompanyINN = async (stringINN: string) => {
        const result = await getFetchSuggestions(stringINN, txtCompanyKPP.value)
        if (result.status === 200 && result.data.companyName) {
            applyDataSuggestions(result.data.companyName)
        // } else {
        //     return notifyOpen('Контрагент по введенному Вами ИНН не найден.', 'info', 2000, notify) 
        }
    }
    /** Запуск проверки контрагента по ИНН */
    const debounceINN = useDebounce(verificationCompanyINN, 1500)



    /** Поле КПП контрагента - изменение и запуск проверки */
    const txtCompanyKPPOnChange = (value: string) => {
        if (!/^\d+$/.test(value) && value) return

        txtCompanyKPP.setValue(value)
        if (value) debounceKPP(value)
    }
    /** Проверка контрагента по КПП */
    const verificationCompanyKPP = async (stringKPP: string) => {
        const result = await getFetchSuggestions(txtCompanyINN.value, stringKPP)
        if (result.status === 200 && result.data.companyName) {
            applyDataSuggestions(result.data.companyName)
        // } else {
        //     return notifyOpen('Контрагент по введенному Вами ИНН и КПП не найден.', 'info', 2000, notify) 
        }
    }
    /** Запуск проверки контрагента по КПП */
    const debounceKPP = useDebounce(verificationCompanyKPP, 1500)

    /** Обработка ответа */
    const applyDataSuggestions = (result: string) => {
        txtCompanyName.setValue(result)

        // console.log(result.suggestions)
    }

    

    /** Кнопка создать/сохранить в интерфейсе анкеты */
    const handlerBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'companyINN', value: txtCompanyINN.value, validType: 'inn', isRequired: true, errorMessage: 'ИНН контрагента не корректное значение.'},
            {fieldName: 'companyKPP', value: txtCompanyKPP.value, validType: 'kpp', isRequired: isVisibleKPP.value, errorMessage: 'КПП контрагента не корректное значение.'},
            {fieldName: 'companyPhone', value: txtCompanyPhone.value, validType: 'phoneMask', isRequired: true, errorMessage: 'Номер телефона не корректный.'},
            {fieldName: 'companyEmail', value: txtCompanyEmail.value, validType: 'email', isRequired: true, errorMessage: 'Электронная почта не корректная.'},

            {fieldName: 'companyName', value: txtCompanyName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Наименование контрагента, обязательно должно быть заполнено.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            loadTitle.setValue('Отправляю данные на сервер...')

            setCompanyCardSave(cardID || '', {
                actionForm: cardMode || 'new',
            
                companyINN: txtCompanyINN.value,
                companyKPP: txtCompanyKPP.value,
                companyName: txtCompanyName.value,
                companyPhone: txtCompanyPhone.value,
                companyEmail: txtCompanyEmail.value
            }).then((res) => {
                if (res.status === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => { fieldsError.setValue(prevState => ({ ...prevState, [field]: !prevState[field] })) }, 400)
        setTimeout(() => { fieldsError.setValue({ [field]: false }); clearInterval(interval) }, 8000)
    }
    

    /** Свойства передаваемые в UI */
    const propsToUI: ICardCompanyUI = {
        isOpen, cardMode, fieldsError, isLoadingCard, loadTitle,
        txtCompanyINN, txtCompanyKPP, 
        txtCompanyName, txtCompanyPhone, 
        txtCompanyEmail, isVisibleKPP,
        txtCompanyINNOnChange, txtCompanyKPPOnChange,
        handlerBtnSave, handlerButtonCancel,
    }

    return <>
        <CardCompanyUI {...propsToUI} />
    </>
}