import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CreatingReportUI } from "./_interface"
import { ICreatingReportContainer, ICreatingReportUI } from "./_types"

import { useInput } from "hooks/useInput"
import { usePersons } from "store/persons"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"
import { IReportFiles } from "store/persons/_types"


/** Создание отчёта - Контейнер */
export const CreatingReportContainer = ({isOpen, handlerButtonCancel}: ICreatingReportContainer) => {
    const notify = useContext(NotificationContext)

    const isLoadReport = useInput<boolean>(false)
    const loadReportTitle = useInput<string>('Получаю файлы отчётов...')

    const selectedReport = useInput<string>('')
    const reportFilesList = useInput<IReportFiles[]>([])
    
    /** Хранилище - Физические лица */
    const { createReport, getReportFiles } = usePersons(useShallow((state) => ({
        createReport: state.createReport,
        getReportFiles: state.getReportFiles
    })))


    useEffect(() => {
        isOpen && loadDataList()

        return (() => {
            loadReportTitle.setValue('Получаю файлы отчётов...')
            selectedReport.setValue('')
        })
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Загрузка основных данных */
    const loadDataList = () => {
        isLoadReport.setValue(true)
        
        getReportFiles().then((res) => {
            if (res.status === 200) {
                reportFilesList.setValue(res.data?.data || [])
            }
        }).finally(() => {
            isLoadReport.setValue(false)
        })
    }

    /** Кнопка создать отчёт */
    const handlerButtonCreateReport = () => {
        if (!selectedReport.value)  return notifyOpen('Необходимо выбрать отчёт.', 'warning', 2000, notify) 

        loadReportTitle.setValue('Создаю отчёт...')
        isLoadReport.setValue(true)

        createReport(`${selectedReport.value}`, '').finally(() => {
            loadReportTitle.setValue('Получаю файлы отчётов...')
            loadDataList()
        })
    }
    
    /** Свойства передаваемые в компоненту */
    const propsToUI: ICreatingReportUI = {
        isOpen, isLoadReport, loadReportTitle, selectedReport, reportFilesList,
        handlerButtonCancel,
        handlerButtonCreateReport
    }
    
    return <>
        <CreatingReportUI {...propsToUI} />
    </>
}