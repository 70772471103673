import axios from "axios"
import { APP_API_URL } from "./api.service"


const tokenService = () => {
    let refreshTimeoutID: NodeJS.Timeout

    const refreshToken = (expiration: number) => {
        if (expiration >= 20000) {
            const timeoutTrigger = expiration - 10000

            if (!refreshTimeoutID) {
                refreshTimeoutID = setInterval(() => {
                    axios.get(`${APP_API_URL}/auth/refresh`, { withCredentials: true }).then((res) => {
                        // console.log('авто обновление токена')
                        const response = res.data?.data
                        
                        if (response.token && response.tokenExpiration) {
                            // console.log('set')
                            localStorage.setItem('us_id', response.token)
                            localStorage.setItem('us_exp', response.tokenExpiration.toString())
                        } else {
                            // console.log('remove')
                            localStorage.removeItem('us_id')
                            localStorage.removeItem('us_exp')
                            window.location.href = '/'
                        }
                    }).catch((err) => {
                        window.location.href = '/'
                        // console.log(`autoRefreshTokenErr`)
                        // console.log(err)
                    }).finally(() => {
                        // console.log(`autoRefreshTokenFinaly`)
                    })
                }, timeoutTrigger)
            }
        }
    }

    const abortRefreshToken = () => {
        if (refreshTimeoutID) clearInterval(refreshTimeoutID)
    }

    return { refreshToken, abortRefreshToken }
}

export default tokenService()