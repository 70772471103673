import { ICardPersonUI } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconCalendar, IconClearClose, IconInfo, IconLock, IconSelectData, IconUnLock, IconUser } from "images/icons"

import { Button, Divider, Drawer, Empty, Flex, Input, Loader, Table, Toogle, ToolTip } from "components/ui"
import moment from "moment"


/** Анкета физического лица - Интерфейс */
export const CardPersonUI = ({
    isOpen, isLoadingCard, isLoadingCardEducations, isLoadingCardEmployments,
    loadTitle, fieldsError, isDisabledButtonSave,

    txtLastName, txtFirstName, txtSecondName, txtBirthDate,
    txtSnils, txtInn, txtPhone, txtEmail,
    txtCitizenshipID, txtCitizenshipName, txtDisabilityGroupName, txtIsDead, txtPersonChangesDate,

    arrEducationsList, arrEmploymentsList,

    txtPersonSNILSOnChange, txtPersonINNOnChange, 
    handlerOpenDictionary, handlerOpenCalendar, 
    handleOpenCardEducation, handleOpenCardEmployment,
    handleBtnSave, handlerButtonClose,
}: ICardPersonUI) => {

    /** Кнопка очистки поля */
    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    /** Кнопка выбор данных из справочника */
    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }

    /** Кнопка выбора даты из календаря */
    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }

    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconUser color='#fff' width={20} height={20} />
                        <h4>Анкета физического лица</h4>
                    </Flex>

                    <h5>{`${txtLastName.value} ${txtFirstName.value} ${txtSecondName.value || ''}`.trim()}</h5>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonClose}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadTitle.value} backdrop />

                <Flex gap='25px' className='drawer_container-content' padding='2px 0px 0px 0px'>
                    <Flex gap="5px" padding="0 6px 0 0">
                        <Divider prefix={<h4>Основное</h4>} 
                            suffix={
                                <Flex gap="6px" direction="row" alignItems="center">
                                    <span id='infoDateTime' style={{color: 'red', cursor: "help"}}><IconInfo width={16} height={16} /></span> 
                                    <p>{moment(txtPersonChangesDate.value).format('DD.MM.YYYY HH:mm')}</p>
                                </Flex>
                            } 
                        />

                        <Flex gap='15px'>
                            <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.lastName ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Фамилия <p>✸</p></Flex>
                                    <Input placeholder="Фамилия" paddingRight={4} isDisabled={txtIsDead.value} {...txtLastName}
                                        suffix={txtLastName.value && getBtnClear(() => txtLastName.setValue(''))} 
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.firstName ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Имя <p>✸</p></Flex>
                                    <Input placeholder="Имя" paddingRight={4} isDisabled={txtIsDead.value} {...txtFirstName} 
                                        suffix={txtFirstName.value && getBtnClear(() => txtFirstName.setValue(''))} 
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250}>
                                    <Flex direction="row" className="input_header">Отчество</Flex>
                                    <Input placeholder="Отчество" paddingRight={4} isDisabled={txtIsDead.value} {...txtSecondName} 
                                        suffix={txtSecondName.value && getBtnClear(() => txtSecondName.setValue(''))} 
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.birthDate ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Дата рождения <p>✸</p></Flex>
                                    <Input placeholder="Дата рождения" paddingRight={4} type='mask' mask='##.##.####' isDisabled={txtIsDead.value} isReadOnly {...txtBirthDate}
                                        // suffix={getBtnSelectCalendar(() => handlerOpenCalendar(true, 'birthDate', txtBirthDate.value))} 
                                    />
                                </Flex>
                            </Flex>

                            <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.snils ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">СНИЛС {txtCitizenshipID.value === 185 ? <p>✸</p> : ''}</Flex>
                                    <Input placeholder="СНИЛС" paddingRight={4} type='mask' mask='###########' isDisabled={txtIsDead.value}
                                        suffix={txtSnils.value && getBtnClear(() => txtSecondName.setValue(''))}
                                        onChange={(e) => txtPersonSNILSOnChange(e.target.value)} value={txtSnils.value}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.inn ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">ИНН</Flex>
                                    <Input placeholder="ИНН" paddingRight={4} type='mask' mask='############' isDisabled={txtIsDead.value}
                                        suffix={txtInn.value && getBtnClear(() => txtInn.setValue(''))}
                                        onChange={(e) => txtPersonINNOnChange(e.target.value)} value={txtInn.value}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.phone ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Телефон</Flex>
                                    <Input placeholder="Телефон" paddingRight={4} type='mask' mask='###########' isDisabled={txtIsDead.value} {...txtPhone} />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.email ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex direction="row" className="input_header">Email</Flex>
                                    <Input placeholder="Email" paddingRight={4} isDisabled={txtIsDead.value} {...txtEmail} 
                                        suffix={txtEmail.value && getBtnClear(() => txtEmail.setValue(''))} 
                                    />
                                </Flex>
                            </Flex>

                            <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.citizenship ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" className="input_header">Гражданство <p>✸</p></Flex>
                                    <Input placeholder="Гражданство" isReadOnly paddingRight={4} isDisabled={txtIsDead.value} {...txtCitizenshipName} 
                                        suffix={getBtnSelect(() => handlerOpenDictionary(true, 'citizenship'))} 
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250}>
                                    <Flex gap="5px" direction="row" className="input_header">Группа инвалидности</Flex>
                                    <Input placeholder="Группа инвалидности" isReadOnly paddingRight={4} isDisabled={txtIsDead.value} {...txtDisabilityGroupName} 
                                        suffix={getBtnSelect(() => handlerOpenDictionary(true, 'disabilityGroup'))} 
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250}>
                                    <Flex gap="5px" direction="row" className="input_header">Человек является умершим</Flex>
                                    <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                        <div>Человек является умершим</div>
                                        <Toogle checked={txtIsDead.value} onChange={(e) => txtIsDead.setValue(e.target.checked)} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex gap="5px" padding="0 6px 0 0">
                        <Divider prefix={<h4>Образование</h4>} />

                        <Table.Container isLoading={isLoadingCardEducations}>
                            {arrEducationsList.value.length === 0
                                ? <Empty image={imgEmty} title='Данные отсутствуют' size='100px' />
                                : arrEducationsList.value.map((item) => {
                                    return (
                                        <Table.Row key={item.studentSourceGuid} indexContent={`${item.year}г.`} indexContentWidth="70px"
                                            classGridTemplateColumns="educations_grid_columns" paddingInset="2px 8px"
                                            onClick={() => handleOpenCardEducation(true, item.studentSourceGuid)}
                                        >
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.faculty} 👉 {item.specialityCode} - {item.specialityName} ({item.specializationName})</p>
                                                <p style={{ opacity: '.7' }}>{item.financeType} / {item.educationForm} / {item.educationLevel} / {item.status}</p>
                                            </div>

                                            <span id="hiddenFor600" className="table_column_data_center">{moment(item.studentChangesDate).format('DD.MM.YYYY')}<br />{moment(item.studentChangesDate).format('HH:mm:ss')}</span>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Container>
                    </Flex>

                    <Flex gap="5px" padding="0 6px 0 0">
                        <Divider prefix={<h4>Трудоустройство</h4>} suffix={<Button size="sm" padding='0 8px' isDisabled={txtIsDead.value} onClick={() => handleOpenCardEmployment(true, 'new')}>Создать</Button>} />

                        <Table.Container isLoading={isLoadingCardEmployments}>
                            {arrEmploymentsList.value.length === 0
                                ? <Empty image={imgEmty} title='Данные отсутствуют' size='100px' />
                                : arrEmploymentsList.value.map((item) => {
                                    return (
                                        <Table.Row key={item.employmentID} indexContentWidth="70px" classGridTemplateColumns="employments_grid_columns" paddingInset="2px 8px"
                                            onClick={() => handleOpenCardEmployment(true, 'edit', item.employmentID)}
                                            indexContent={
                                                item.endDate
                                                    ? <Flex alignItems="center" color="red"><IconLock /></Flex>
                                                    : <Flex alignItems="center" color="#00cd00"><IconUnLock /></Flex>
                                            }
                                        >
                                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                                <p>{item.employmentStatusName} 👉 от {item.startDate} по {item.endDate || 'текущий день'}</p>
                                                <p style={{ opacity: '.7' }}>Основное место работы: {item.isMain === 1 ? 'Да' : 'Нет'}</p>
                                            </div>

                                            <span id="hiddenFor600" className="table_column_data_center">{moment(item.employmentChangesDate).format('DD.MM.YYYY')}<br />{moment(item.employmentChangesDate).format('HH:mm:ss')}</span>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Container>
                    </Flex>
                </Flex>

                <Divider />
                <Button isDisabled={isDisabledButtonSave.value} onClick={handleBtnSave}>Сохранить изменения</Button>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#infoDateTime" content='Дата последних изменений' place="bottom" />        
    </Drawer>
}