import { ICardEducationUI } from './_types'

import { useWindowSize } from 'hooks/useWindowSize'

import { IconClearClose, IconInfo, IconStudents } from 'images/icons'
import { Button, Divider, Drawer, Flex, Input, Loader, Toogle, ToolTip } from 'components/ui'


/** Анкета по образованию физического лица - Интерфейс */
export const CardEducationUI = ({ 
    isOpen, isDead, personFIO, isDisabledButtonSave, loadTitle, isLoadingCard,

    txtYear, txtFaculty, txtStartEducationDate, txtEndEducationDate,
    txtCourse, txtEducationForm, txtEducationLevel, txtFinanceType,
    txtSpecialityName, txtSpecializationName, txtStatus,
    txtDegreeDate, txtIsTargetDirection, txtIsProfessionalProgram,  

    handleOpenDictionary, handleBtnSave, handleBtnCancel 
}: ICardEducationUI) => {
    const { width } = useWindowSize()

    /** Кнопка выбор данных из справочника */
    // const getBtnSelect = (handleBtnClick: () => void) => {
    //     return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    // }


    return <Drawer isOpen={isOpen} size={`${width > 600 ? `600px` : `${width - 4}px`}`} placement='right'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconStudents color='#fff' width={20} height={20} />
                        <h4>Анкета по образованию</h4>
                    </Flex>

                    <h5>{personFIO}</h5>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handleBtnCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadTitle.value} backdrop />

                <Flex gap='20px' direction='column' className='drawer_container-content'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Год выпуска <p>✸</p></Flex>
                            <Input placeholder="Год выпуска" isReadOnly {...txtYear} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Факультет <p>✸</p></Flex>
                            <Input placeholder="Факультет" isReadOnly {...txtFaculty} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Начало обучения <p>✸</p></Flex>
                            <Input placeholder="Начало обучения" isReadOnly {...txtStartEducationDate} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Конец обучения <p>✸</p></Flex>
                            <Input placeholder="Конец обучения" isReadOnly {...txtEndEducationDate} />
                        </Flex>
                    </Flex>

                    <Divider />

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Курс <p>✸</p></Flex>
                            <Input placeholder="Курс" isReadOnly {...txtCourse} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Форма обучения <p>✸</p></Flex>
                            <Input placeholder="Форма обучения" isReadOnly {...txtEducationForm} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Уровень образования <p>✸</p></Flex>
                            <Input placeholder="Уровень образования" isReadOnly {...txtEducationLevel} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Вид финансирования <p>✸</p></Flex>
                            <Input placeholder="Вид финансирования" isReadOnly {...txtFinanceType} />
                        </Flex>
                    </Flex>

                    <Divider />

                    <Flex gap="8px">
                        <Flex>
                            <Flex gap="5px" direction="row" className="input_header">Код и наименование специальности <p>✸</p></Flex>
                            <Input placeholder="Код и наименование специальности" isReadOnly {...txtSpecialityName} />
                        </Flex>

                        <Flex>
                            <Flex gap="5px" direction="row" className="input_header">Наименование специализации <p>✸</p></Flex>
                            <Input placeholder="Наименование специализации" isReadOnly {...txtSpecializationName} />
                        </Flex>
                    </Flex>

                    <Divider />

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                {/* <span id='helpStatus' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span> */}
                                <span>Текущий статус</span>
                                <p>✸</p>
                            </Flex>

                            <Input placeholder="Текущий статус" isReadOnly {...txtStatus} 
                                // suffix={getBtnSelect(() => handleOpenDictionary(true, 'employmentStatus'))}
                            />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Дата выдачи диплома <p>✸</p></Flex>
                            <Input placeholder="Дата выдачи диплома" isReadOnly {...txtDegreeDate} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" className="input_header">Целевое направление</Flex>
                            <Input placeholder="Целевое направление" isReadOnly {...txtIsTargetDirection} />
                        </Flex>

                        <Flex flexGrow={1} flexBasis={250}>
                            <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                <span id='helpIsProfessionalProgram' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                <span>Профессионалитет</span>
                            </Flex>

                            <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                <div>Профессионалитет</div>
                                <Toogle checked={txtIsProfessionalProgram.value} onChange={(e) => txtIsProfessionalProgram.setValue(e.target.checked)} />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Divider />
                <Button isDisabled={isDead ? true : isDisabledButtonSave.value} onClick={handleBtnSave}>Сохранить изменения</Button>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />
        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />

        {/* <ToolTip anchorSelect="#helpStatus" place="bottom">
            Выбор из списка<br/>
            <li>Учится</li>
            <li>Отчислен</li>
            <li>Выпущен</li>
        </ToolTip> */}

        <ToolTip anchorSelect="#helpIsProfessionalProgram" place="bottom">
            Пометка о том, что обучение<br/>студента происходит на<br/>программе профессионалитета<br/>
        </ToolTip>
    </Drawer>
}