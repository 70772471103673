import axios, { AxiosError, AxiosResponse } from 'axios'

// export const APP_API_URL='http://192.168.1.105:5008/api'
// -- export const APP_WEB_SOCKET='ws://192.168.1.105:5008/api'

// export const APP_API_URL='http://192.168.0.167:5008/api'
// -- export const APP_WEB_SOCKET='ws://192.168.0.167:5008/api'

export const APP_API_URL='https://mon.vavilovsar.ru/api/'
// -- export const APP_WEB_SOCKET='wss://mon.vavilovsar.ru/ws'


axios.defaults.withCredentials = true
const api = axios.create({
    baseURL: APP_API_URL,
    withCredentials: true
})

api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('us_id')}`

    return config
}, (error) => {
    Promise.reject(error)
})

api.interceptors.response.use(async (config) => {
    return config
}, ( async (error) => {
    const originalRequest = error.config

    if ((error as AxiosError).code !== 'ERR_NETWORK') {
        if (error.response.status === 401) {
            try {
                const response = await axios.get(`${APP_API_URL}/auth/refresh`)

                if (response.data.token && response.data.tokenExpiration) {
                    localStorage.setItem('us_id', response.data.token)
                    localStorage.setItem('us_exp', response.data.tokenExpiration.toString())
                } else { 
                    localStorage.removeItem('us_id') 
                    localStorage.removeItem('us_exp')

                    window.location.href = '/'
                }

                return api.request(originalRequest)
            } catch (e) {
                localStorage.removeItem('us_id')
                localStorage.removeItem('us_exp')
                
                window.location.href = '/'
            }
        } else {
            return error.response
        }
    } else { 
        return {statusText: error.code}
    }
}))

export default api


interface IResponseBase {
    status: number
    message: string
    errors: any
}

export const handleRequest = async <T extends IResponseBase>(request: Promise<AxiosResponse<T, AxiosError>>): Promise<T> => {
    try {
        const res = await request

        if (res.statusText !== 'ERR_NETWORK') {
            return res.data
        } else {
            return {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null,
            } as T
        }
    } catch (error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {
            return {
                status: 500,
                message: 'Сервер не доступен',
                errors: (error as AxiosError).code,
            } as T
        }

        const resError = (error as AxiosError).response?.data as T
        return {
            status: 500,
            message: resError.message,
            errors: resError.errors,
        } as T
    }
}
