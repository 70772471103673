import { useEffect, useState } from "react"


/** Узнаем и возвращаем ширину и высоту экрана */
export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        // Функция, которая обновляет размеры окна
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        // Добавляем обработчик события resize
        window.addEventListener('resize', handleResize)

        // Убираем обработчик при размонтировании компонента
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Пустой массив зависимостей, чтобы эффект выполнялся только при монтировании и размонтировании

    return windowSize
}