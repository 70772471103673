import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardPersonUI } from "./_interface"
import { FieldsErrorType, ICardPersonContainer, ICardPersonUI, IFieldsError } from "./_types"

import { useInput } from "hooks/useInput"
import { validFormData } from "hooks/useForm"

import { usePersons } from "store/persons"
import { IPersonCardEducation, IPersonCardEmployment } from "store/persons/_types"

import { IModalCalendar, ModalCalendar } from "components/dumb/modal.calendar"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"

import { CardModeType } from "../common.types"
import { CardEducationContainer, ICardEducationContainer } from "../card_education"
import { DictNameType, ISelectionDictionaryContainer, SelectionDictionaryContainer } from "../selection_dictionary"
import { CardEmploymentContainer, ICardEmploymentContainer } from "../card_employment"
import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"


/** Анкета физического лица - Контейнер */
export const CardPersonContainer = ({ isOpen, cardID, handlerButtonOk, handlerButtonCancel }: ICardPersonContainer) => {
    const notify = useContext(NotificationContext)

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalDictionary = useInput<ISelectionDictionaryContainer>({isOpen: false})
    const modalCardEducation = useInput<ICardEducationContainer>({isOpen: false})
    const modalCardEmployment = useInput<ICardEmploymentContainer>({isOpen: false})

    const isDisabledButtonSave = useInput<boolean>(true)

    const loadTitle = useInput<string>('Загрузка данных...')
    const fieldsError = useInput<IFieldsError>({})
    
    const txtLastName = useInput<string>('')
    const txtFirstName = useInput<string>('')
    const txtSecondName = useInput<string>('')
    const txtBirthDate = useInput<string>('')
    const txtSnils = useInput<string>('')
    const txtInn = useInput<string>('')
    const txtPhone = useInput<string>('')
    const txtEmail = useInput<string>('')
    const txtCitizenshipID = useInput<number | ''>('')
    const txtCitizenshipName = useInput<string>('')
    const txtDisabilityGroupID = useInput<number | ''>('')
    const txtDisabilityGroupName = useInput<string>('')
    const txtIsDead = useInput<boolean>(false)
    const txtPersonChangesDate = useInput<string>('')

    const arrEducationsList = useInput<IPersonCardEducation[]>([])
    const arrEmploymentsList = useInput<IPersonCardEmployment[]>([])


    /** Хранилище - Физические лица */
    const { 
        isLoadingCard, isLoadingCardEducations, isLoadingCardEmployments,
        isReloadEducationCard, isReloadEmploymentCard,
        lastName, firstName, secondName, birthDate, snils, inn, phone, email,
        citizenshipName, disabilityGroupName, isDead,
        clearPersonCard, savePersonCard, getPersonCard, getPersonCardEducations, getPersonCardEmployments,
    } = usePersons(useShallow((state) => ({
        isLoadingCard: state.personCard.isLoading,
        isReloadEducationCard: state.personCardEducations.isReload,
        isReloadEmploymentCard: state.personCardEmployments.isReload,

        isLoadingCardEducations: state.personCardEducations.isLoading,
        isLoadingCardEmployments: state.personCardEmployments.isLoading,

        lastName: state.personCard.items.lastName,
        firstName: state.personCard.items.firstName,
        secondName: state.personCard.items.secondName,
        birthDate: state.personCard.items.birthDate,

        snils: state.personCard.items.snils,
        inn: state.personCard.items.inn,
        
        phone: state.personCard.items.phone,
        email: state.personCard.items.email,

        citizenshipID: state.personCard.items.citizenshipID,
        citizenshipName: state.personCard.items.citizenshipName,
        
        disabilityGroupID: state.personCard.items.disabilityGroupID,
        disabilityGroupName: state.personCard.items.disabilityGroupName,
        
        isDead: state.personCard.items.isDead,
        personChangesDate: state.personCard.items.personChangesDate,

        clearPersonCard: state.clearPersonCard,
        getPersonCard: state.getPersonCard,
        getPersonCardEducations: state.getPersonCardEducations,
        getPersonCardEmployments: state.getPersonCardEmployments,
        savePersonCard: state.setPersonCardSave
        // setCompanyCardSave: state.setCompanyCardSave
    })))
    

    useEffect(() => {
        if (isOpen) {
            getPersonCard(cardID || '').then((res) => {
                if (res.status === 200) {
                    txtLastName.setValue(res.data?.lastName || '')
                    txtFirstName.setValue(res.data?.firstName || '')
                    txtSecondName.setValue(res.data?.secondName || '')

                    txtBirthDate.setValue(res.data?.birthDate || '')
                    txtSnils.setValue(res.data?.snils || '')
                    txtInn.setValue(res.data?.inn || '')

                    txtPhone.setValue(res.data?.phone || '')
                    txtEmail.setValue(res.data?.email || '')

                    txtCitizenshipID.setValue(res.data?.citizenshipID || '')
                    txtCitizenshipName.setValue(res.data?.citizenshipName || '')

                    txtDisabilityGroupID.setValue(res.data?.disabilityGroupID || '')
                    txtDisabilityGroupName.setValue(res.data?.disabilityGroupName || '')

                    txtIsDead.setValue(res.data?.isDead === 1 ? true : false)
                    txtPersonChangesDate.setValue(res.data?.personChangesDate || '')

                    getPersonCardEducations(cardID || '').then((res) => {
                        if (res.status === 200) {
                            res.data?.data && arrEducationsList.setValue(res.data?.data)
                        }
                    })

                    getPersonCardEmployments(cardID || '').then((res) => {
                        if (res.status === 200) {
                            res.data?.data && arrEmploymentsList.setValue(res.data?.data)
                        }
                    })                    
                }
            })
        } else {
            clearPersonCard()

            isDisabledButtonSave.setValue(true)
            loadTitle.setValue('Загрузка данных...')
        
            txtLastName.setValue('')
            txtFirstName.setValue('')
            txtSecondName.setValue('')
            txtBirthDate.setValue('')
            txtSnils.setValue('')
            txtInn.setValue('')
            txtPhone.setValue('')
            txtEmail.setValue('')
            txtCitizenshipID.setValue('')
            txtDisabilityGroupName.setValue('')
            txtDisabilityGroupID.setValue('')
            txtDisabilityGroupName.setValue('')
            txtIsDead.setValue(false)
            txtPersonChangesDate.setValue('')

            arrEducationsList.setValue([])
            arrEmploymentsList.setValue([])
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen) {
            let vPhone = txtPhone.value.replace(/\D/g, '')

            /** Условия для активации кнопки (Сохранить изменения) */
            const isFormChanged = (
                txtLastName.value !== lastName || 
                txtFirstName.value !== firstName || 
                txtSecondName.value !== secondName || 
                txtBirthDate.value !== birthDate ||
                txtSnils.value !== (snils ? snils : '') ||
                txtInn.value !== (inn ? inn : '') ||
                vPhone !== (phone ? phone : '') ||
                txtEmail.value !== (email ? email : '') ||
                txtCitizenshipName.value !== citizenshipName ||
                txtDisabilityGroupName.value !== (disabilityGroupName ? disabilityGroupName : '') ||
                txtIsDead.value !== (isDead === 1 ? true : false)
            )

            /** Активируем кнопку сохранить изменения, в случае если одно из условий выполнено */
            if (isFormChanged) { isDisabledButtonSave.setValue(false) } 
            else { isDisabledButtonSave.setValue(true) } 
        }
    }, [
        txtLastName.value, txtFirstName.value, txtSecondName.value, txtBirthDate.value,
        txtSnils.value, txtInn.value, txtPhone.value, txtEmail.value,
        txtCitizenshipName.value, txtDisabilityGroupName.value, txtIsDead.value,
    
        lastName, firstName, secondName, birthDate, snils, inn, phone, email,  
        citizenshipName, disabilityGroupName, isDead,
    
        isDisabledButtonSave, isOpen
    ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen) {
            if (isReloadEducationCard) {
                getPersonCardEducations(cardID || '').then((res) => {
                    if (res.status === 200) {
                        res.data?.data && arrEducationsList.setValue(res.data?.data)
                    }
                })
            }

            if (isReloadEmploymentCard) {
                getPersonCardEmployments(cardID || '').then((res) => {
                    if (res.status === 200) {
                        res.data?.data && arrEmploymentsList.setValue(res.data?.data)
                    }
                })
            }
        }
    }, [isOpen, isReloadEducationCard, isReloadEmploymentCard]) // eslint-disable-line react-hooks/exhaustive-deps
    
    

    /** Открытие/закрытие окна подтверждения */
    const handleOpenConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText,
            handlerButtonCancel() { modalConfirm.setValue({ isOpen: false }) },
            handlerButtonOk() {
                modalConfirm.setValue({ isOpen: false })
                handlerButtonCancel && handlerButtonCancel()
            }
        })
    }
    /** Открытие/закрытие окна выбора даты в календаре */
    const handlerOpenCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'birthDate': txtBirthDate.setValue(stringDate); break
                }
                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handlerOpenDictionary = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDictionary.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() { modalDictionary.setValue({ isOpen: false }) },
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'citizenship': { // Гражданство
                        txtCitizenshipID.setValue(Number(id))
                        txtCitizenshipName.setValue(name)
                        break
                    }

                    case 'disabilityGroup': { // Группа инвалидности
                        txtDisabilityGroupID.setValue(Number(id))
                        txtDisabilityGroupName.setValue(name)
                        break
                    }
                }

                modalDictionary.setValue({ isOpen: false })
            }
        })
    }



    /** Открытие/закрытие окна анкеты по образованию */
    const handleOpenCardEducation = (isOpen: boolean, cardID: string) => {
        modalCardEducation.setValue({
            isOpen, cardID, isDead: txtIsDead.value,
            personFIO: `${txtLastName.value} ${txtFirstName.value} ${txtSecondName.value || ''}`.trim(),
            handleButtonCancel() { modalCardEducation.setValue({isOpen: false}) }, 
            handlerButtonOk() {
                modalCardEducation.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна анкеты по трудоустройству */
    const handleOpenCardEmployment = (isOpen: boolean, cardMode: CardModeType, employmentID?: string) => {
        modalCardEmployment.setValue({
            isOpen, cardMode, cardID: employmentID, personID: cardID, isDead: txtIsDead.value,
            personFIO: `${txtLastName.value} ${txtFirstName.value} ${txtSecondName.value || ''}`.trim(),
            handleButtonCancel() { modalCardEmployment.setValue({isOpen: false}) }, 
            handlerButtonOk() {
                modalCardEmployment.setValue({isOpen: false})
            }
        })
    }


    /** Поле ИНН физического лица - изменение и запуск проверки */
    const txtPersonINNOnChange = (value: string) => {
        if (!/^\d+$/.test(value) || !value || value.length > 12) return

        txtInn.setValue(value)
    }
    /** Поле СНИЛС физического лица - изменение и запуск проверки */
    const txtPersonSNILSOnChange = (value: string) => {
        if (!/^\d+$/.test(value) || !value || value.length > 11) return

        txtSnils.setValue(value)
    }


    /** Кнопка создать/сохранить в интерфейсе анкеты */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'lastName', value: txtLastName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Фамилия некорректное значение.'},
            {fieldName: 'firstName', value: txtFirstName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Имя некорректное значение.'},
            {fieldName: 'birthDate', value: txtBirthDate.value, validType: 'date', isRequired: true, errorMessage: 'Дата рождения некорректное значение.'},
            
            {fieldName: 'snils', value: txtSnils.value, validType: 'snils', isRequired: txtCitizenshipID.value === 185, errorMessage: 'СНИЛС некорректное значение.'},
            {fieldName: 'inn', value: txtInn.value, validType: 'inn', isRequired: false, errorMessage: 'ИНН некорректное значение.'},
            
            {fieldName: 'phone', value: txtPhone.value, validType: 'phoneMask', isRequired: false, errorMessage: 'Номер телефона некорректное значение.'},
            {fieldName: 'email', value: txtEmail.value, validType: 'email', isRequired: false, errorMessage: 'Электронная почта некорректное значение.'},
            
            {fieldName: 'citizenship', value: txtCitizenshipName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Гражданство необходимо указать.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            loadTitle.setValue('Отправляю данные на сервер...')

            savePersonCard(cardID || '', {
                lastName: txtLastName.value,
                firstName: txtFirstName.value,
                secondName: txtSecondName.value,

                snils: txtSnils.value,
                inn: txtInn.value,
                
                phone: txtPhone.value,
                email: txtEmail.value,
                
                citizenshipID: txtCitizenshipID.value,
                disabilityGroupID: txtDisabilityGroupID.value,
                isDead: txtIsDead.value ? 1 : 0,
            }).then((res) => {
                if (res.status === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => { fieldsError.setValue(prevState => ({ ...prevState, [field]: !prevState[field] })) }, 400)
        setTimeout(() => { fieldsError.setValue({ [field]: false }); clearInterval(interval) }, 8000)
    }
    /** Кнопка закрытия окна */
    const handlerButtonClose = () => {
        if (isDisabledButtonSave.value) {
            handlerButtonCancel && handlerButtonCancel()
        } else {
            handleOpenConfirm(true, 'Сохранение формы', '<b>Внимание!</b> У Вас имеются не сохранённые данные по текущей форме!<br/><br/>Вы действительно хотите закрыть текущее окно, без сохранения внесённых Вами изменений?')
        }
    }


    /** Свойства передаваемые в UI */
    const propsToUI: ICardPersonUI = {
        isOpen, isLoadingCard, isLoadingCardEducations, isLoadingCardEmployments,
        loadTitle, fieldsError, isDisabledButtonSave,
        txtLastName, txtFirstName, txtSecondName, txtBirthDate,
        txtSnils, txtInn, txtPhone, txtEmail,
        txtCitizenshipID, txtCitizenshipName, txtDisabilityGroupName, 
        txtIsDead, txtPersonChangesDate,

        arrEducationsList, arrEmploymentsList,

        txtPersonSNILSOnChange, txtPersonINNOnChange,
        handlerOpenDictionary, handlerOpenCalendar, 
        handleOpenCardEducation, handleOpenCardEmployment,
        handleBtnSave, handlerButtonClose,
    }
    
    return <>
        <CardPersonUI {...propsToUI} />
        <CardEducationContainer {...modalCardEducation.value} />
        <CardEmploymentContainer {...modalCardEmployment.value} />

        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />
        <SelectionDictionaryContainer {...modalDictionary.value} />
    </>
}