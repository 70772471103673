import React, { memo } from 'react'

import { IToogle } from './type'
import css from './index.module.scss'


export const Toogle = memo(({
    title, checked, isDisabled = false, onChange
}: IToogle): React.JSX.Element => {
    return <section className={css.myPopoverVertical}>
        {/* {<span>{title}</span>} */}
        <input type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={isDisabled}
        // onChange={(e) => console.log(e.target.checked)} 
        />
    </section>
})