import React, { memo } from 'react'

import css from './index.module.scss'
import { EmptyType } from './type'

/** Компонент Пустого состояния (Empty). 
* @param {string} image - Ссылка на изображение, связанное с пустым состоянием.
* @param {string} title - Текстовое сообщение о пустом состоянии.
* @param {string} size - Размер изображения.
* @param {string} color - Цвет текста.
* @param {string} fontSize - Размер шрифта текста.
*/
export const Empty = memo(({ myKey, image, title, size = "150px", fontSize = '14px', isVisible = true }: EmptyType): React.JSX.Element => {
    return <>
        {!isVisible ? ''
            : <div key={myKey} className={css.wrapper}>
                <div className={css.container}>
                    <img src={`${image}`} alt="Данные отсутствуют" style={{ height: `${size}` }} />
                    <div className={css.title} style={{ fontSize: `${fontSize}` }}>{title}</div>
                </div>
            </div>
        }
    </>
})