import { ICardEmploymentUI } from './_types'

import { useWindowSize } from 'hooks/useWindowSize'

import { IconCalendar, IconClearClose, IconEmployment, IconInfo, IconSelectData } from 'images/icons'
import { Button, Divider, Drawer, Flex, Input, Loader, Toogle, ToolTip } from 'components/ui'


/** Анкета по трудоустройству физического лица - Интерфейс */
export const CardEmploymentUI = ({
    isOpen, cardMode, isDead, personFIO, isDisabledButtonSave, fieldsError,
    loadTitle, isLoadingCard,

    txtEmploymentStatusID, txtEmploymentStatusName,
    txtEmploymentStatusStartDate, txtEmploymentStatusEndDate,
    txtEmploymentStatusIsConfirmed, txtReasonForUnemploymentName,

    txtCompanyName, txtIsForeignCompany, txtIsProvidedLivingSpace, txtPosition, 

    txtIsEmployedBySpeciality, txtIsEmployedOnTerritorySubject, 
    txtIsMain, txtEstimatedSalary, txtEmploymentDurationName,

    handleChangeEstimatedSalary, handlerOpenSelectionCompany,
    handleOpenCalendar, handleOpenDictionary,
    handleBtnSave, handleBtnCancel
}: ICardEmploymentUI) => {
    const { width } = useWindowSize()


    /** Кнопка очистки поля */
    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }

    /** Кнопка выбор данных из справочника */
    const getBtnSelect = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconSelectData /></Button>
    }

    /** Кнопка выбора даты из календаря */
    const getBtnSelectCalendar = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnSelect" size="sm" variant="icon" onClick={handleBtnClick}><IconCalendar /></Button>
    }


    return <Drawer isOpen={isOpen} size={`${width > 600 ? `600px` : `${width - 4}px`}`} placement='right'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconEmployment color='#fff' width={20} height={20} />
                        <h4>{cardMode === 'edit' ? 'Анкета по трудоустройству' : 'Создание записи по трудоустройству'}</h4>
                    </Flex>

                    <h5>{personFIO}</h5>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handleBtnCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoadingCard} color='#f34e2e' title={loadTitle.value} backdrop />

                <Flex gap='6px' className='drawer_container-content'>
                    <Flex gap='20px'>
                        <Flex gap='8px'>
                            <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.employmentStatus ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpEmploymentStatus' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>Статус трудоустройства</span>
                                        <p>✸</p>
                                    </Flex>

                                    <Input placeholder="Статус трудоустройства" isReadOnly paddingRight={4} {...txtEmploymentStatusName}
                                        suffix={getBtnSelect(() => handleOpenDictionary(true, 'employmentStatus'))}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpIsConfirmed' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>Подтверждающие документы</span>
                                    </Flex>

                                    <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                        <div>Статус подтверждён</div>
                                        <Toogle checked={txtEmploymentStatusIsConfirmed.value} onChange={(e) => txtEmploymentStatusIsConfirmed.setValue(e.target.checked)} />
                                    </Flex>
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.employmentStatusStartDate ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpEmploymentStatusStartDate' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>Дата начала статуса</span>
                                        <p>✸</p>
                                    </Flex>

                                    <Input placeholder="Дата начала статуса" paddingRight={4} type='mask' mask='##.##.####'
                                        suffix={getBtnSelectCalendar(() => handleOpenCalendar(true, 'employmentStatusStartDate', txtEmploymentStatusStartDate.value))} {...txtEmploymentStatusStartDate}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.employmentStatusEndDate ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpEmploymentStatusEndDate' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>Дата окончания статуса</span>
                                    </Flex>

                                    <Input placeholder="Дата окончания статуса" paddingRight={4} type='mask' mask='##.##.####'
                                        suffix={getBtnSelectCalendar(() => handleOpenCalendar(true, 'employmentStatusEndDate', txtEmploymentStatusEndDate.value))} {...txtEmploymentStatusEndDate}
                                    />
                                </Flex>
                            </Flex>

                            <Flex isVisible={![1, 2, 3, 4].includes(txtEmploymentStatusID.value || 0)} className={!fieldsError.value.reasonForUnemploymentName ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpReasonForUnemploymentName' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>Причина нетрудоустройства</span>
                                    <p>✸</p>
                                </Flex>

                                <Input placeholder="Причина нетрудоустройства" isReadOnly paddingRight={4} {...txtReasonForUnemploymentName}
                                    suffix={getBtnSelect(() => handleOpenDictionary(true, 'reasonForUnemployment'))}
                                />
                            </Flex>
                        </Flex>

                        <Divider isVisible={![1, 5, 4].includes(txtEmploymentStatusID.value || 0)} />

                        <Flex gap='8px' isVisible={![1, 4, 5].includes(txtEmploymentStatusID.value || 0)}>
                            <Flex isVisible={![3].includes(txtEmploymentStatusID.value || 0)}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpIsForeignCompany' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 Работает в компании за границей</span>
                                </Flex>

                                <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                    <div>Работает в компании за границей</div>
                                    <Toogle checked={txtIsForeignCompany.value} onChange={(e) => txtIsForeignCompany.setValue(e.target.checked)} />
                                </Flex>
                            </Flex>

                            <Flex isVisible={!txtIsForeignCompany.value} className={!fieldsError.value.companyName ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpCompanyName' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 Наименование контрагента</span>
                                    <p>✸</p>
                                </Flex>

                                <Input placeholder="Наименование контрагента" isReadOnly paddingRight={4} {...txtCompanyName}
                                    suffix={getBtnSelect(() => handlerOpenSelectionCompany(true))}
                                />
                            </Flex>

                            <Flex isVisible={![3].includes(txtEmploymentStatusID.value || 0)} gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                                <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.position ? 'input_error' : 'input_error input_error-active'}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpPosition' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>1 Занимаемая должность</span>
                                        <p>✸</p>
                                    </Flex>

                                    <Input placeholder="Занимаемая должность" paddingRight={4} {...txtPosition}
                                        suffix={txtPosition.value && getBtnClear(() => txtPosition.setValue(''))}
                                    />
                                </Flex>

                                <Flex flexGrow={1} flexBasis={250}>
                                    <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                        <span id='helpIsProvidedLivingSpace' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                        <span>1 Предоставляется жил площадь</span>
                                    </Flex>

                                    <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                        <div>Жил площадь</div>
                                        <Toogle checked={txtIsProvidedLivingSpace.value} onChange={(e) => txtIsProvidedLivingSpace.setValue(e.target.checked)} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Divider isVisible={![1, 5].includes(txtEmploymentStatusID.value || 0)} />

                        <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap" isVisible={![1, 5].includes(txtEmploymentStatusID.value || 0)}>
                            <Flex isVisible={![4].includes(txtEmploymentStatusID.value || 0)} flexGrow={1} flexBasis={250}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpIsEmployedBySpeciality' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 Работает по специальности</span>
                                </Flex>

                                <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                    <div>По специальности</div>
                                    <Toogle checked={txtIsEmployedBySpeciality.value} onChange={(e) => txtIsEmployedBySpeciality.setValue(e.target.checked)} />
                                </Flex>
                            </Flex>

                            <Flex isVisible={![4].includes(txtEmploymentStatusID.value || 0)} flexGrow={1} flexBasis={250}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpIsEmployedOnTerritorySubject' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 На территории субъекта</span>
                                </Flex>

                                <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                    <div>На территории субъекта</div>
                                    <Toogle checked={txtIsEmployedOnTerritorySubject.value} onChange={(e) => txtIsEmployedOnTerritorySubject.setValue(e.target.checked)} />
                                </Flex>
                            </Flex>

                            <Flex flexGrow={1} flexBasis={250}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpIsMain' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 3 Основное место работы</span>
                                </Flex>

                                <Flex gap="4px" direction="row" alignItems="center" justifyContent="space-between" className="section" padding="3px 3px 3px 8px">
                                    <div>Основное место работы</div>
                                    <Toogle checked={txtIsMain.value} onChange={(e) => txtIsMain.setValue(e.target.checked)} />
                                </Flex>
                            </Flex>

                            <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.estimatedSalary ? 'input_error' : 'input_error input_error-active'}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpEstimatedSalary' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 3 Ожидаемый оклад</span>
                                    <p>✸</p>
                                </Flex>

                                <Input placeholder="Ожидаемый оклад" paddingRight={4}
                                    suffix={txtEstimatedSalary.value && getBtnClear(() => txtEstimatedSalary.setValue(''))}
                                    value={txtEstimatedSalary.value} onChange={(e) => handleChangeEstimatedSalary(e.target.value)}
                                />
                            </Flex>

                            <Flex flexGrow={1} flexBasis={250}>
                                <Flex gap="5px" direction="row" alignItems="center" className="input_header">
                                    <span id='helpEmploymentDurationName' style={{ color: 'red', cursor: "help" }}><IconInfo width={16} height={16} /></span>
                                    <span>1 2 3 Продолжительность занятости</span>
                                </Flex>

                                <Input placeholder="Продолжительность занятости" isReadOnly paddingRight={4} {...txtEmploymentDurationName}
                                    suffix={getBtnSelect(() => handleOpenDictionary(true, 'employmentDuration'))}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Divider />
                <Button isDisabled={isDead ? true : isDisabledButtonSave.value} onClick={handleBtnSave}>
                    {cardMode === 'edit' ? 'Сохранить изменения' : 'Создать новую запись'}
                </Button>
            </Flex>
        </Flex>


        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
        <ToolTip anchorSelect="#tooltipBtnSelect" content='Выбрать' place="left" />


        <ToolTip anchorSelect="#helpEmploymentStatus" place="bottom-start">
            Выбор из списка:<br/>
            <li>Военный по контракту</li>
            <li>Трудоустроен</li>
            <li>Индивидуальный предприниматель</li>
            <li>Самозанятый</li>
            <li>Нетрудоустроен</li>
        </ToolTip>

        <ToolTip anchorSelect="#helpIsConfirmed" place="bottom-start">
            Пометка о том, что в ВУЗе имеются<br/>подтверждающие документы<br/>о статусе трудоустройства.<br/>
        </ToolTip>

        <ToolTip anchorSelect="#helpEmploymentStatusStartDate" place="bottom-start">
            Дата начала действия текущего статуса.<br/>
            Не может быть пустым.<br/>
            Формат даты: день.месяц.год
        </ToolTip>

        <ToolTip anchorSelect="#helpEmploymentStatusEndDate" place="bottom-start">
            Дата окончания действия текущего статуса.<br/>
            Может быть пустым значением, пока запись о<br/>трудоустройстве не закрыта и является действующей.<br/>
            Формат даты: день.месяц.год
        </ToolTip>
        
        <ToolTip anchorSelect="#helpReasonForUnemploymentName" place="bottom-start">
            Выбор из списка.<br/>
            Заполняется для тех, кто нетрудоустроен.<br/>
            Медицинские противопоказания включают<br/>
            в том числе инвалидность человека<br/>
            (обязательно должна быть проставлена<br/>
            инвалидность в анкете у физического лица).<br/>
            
        </ToolTip>


        <ToolTip anchorSelect="#helpCompanyName" place="bottom-start">
            Обязательно не пустое,<br/>когда человек трудоустроен<br/>или является предпринимателем.<br/>
        </ToolTip>

        <ToolTip anchorSelect="#helpIsForeignCompany" place="bottom-start">
            Пометка о том, что человек<br/>трудоустроен в иностранной<br/>компании, находящаяся за границей<br/>(в таком случае контрагента<br/>указывать не нужно).
        </ToolTip>

        <ToolTip anchorSelect="#helpIsProvidedLivingSpace" place="bottom-start">
            Пометка о том, предоставляется<br/>ли жилая площадь для сотрудника.<br/>
        </ToolTip>

        <ToolTip anchorSelect="#helpPosition" place="bottom-start">
            Должность, занимаемая<br/>физическим лицом.<br/>
        </ToolTip>


        <ToolTip anchorSelect="#helpIsEmployedBySpeciality" place="bottom-start">
            Пометка о том, трудоустроен<br/>ли человек по специальности.
        </ToolTip>

        <ToolTip anchorSelect="#helpIsEmployedOnTerritorySubject" place="bottom-start">
            Пометка о том, трудоустроен<br/>ли человек на территории субъекта ВУЗа.
        </ToolTip>

        <ToolTip anchorSelect="#helpIsMain" place="bottom-start">
            Пометка о том, является ли<br/>текущееместо работы основным.
        </ToolTip>

        <ToolTip anchorSelect="#helpEstimatedSalary" place="bottom-start">
            Ожидаемая заработная плата.
        </ToolTip>

        <ToolTip anchorSelect="#helpEmploymentDurationName" place="bottom-start">
            Продолжительность занятости<br/>физического лица.
        </ToolTip>
    </Drawer>
}