import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { MainInterface } from './_interface'
import { IMainInterface } from './_types'

import { useInput } from 'hooks/useInput'
import { useLogin } from 'store/login/_state'
import { resetStore } from 'store/reset.store'  

import { DrawerMenu, IDrawerMenu } from 'components/dumb/drawer.menu'
import { IModalConfirm, ModalConfirm } from 'components/dumb/modal.confirm'

import { Loader } from 'components/ui'
import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Главная - Контейнер */
export const MainContainer = () => {
    const navigate = useNavigate()
    const notify = useContext(NotificationContext)

    const { 
        isLoading, errorLogin, userLastName, userFirstName, userSecondName,
        checkUserAuth, getUserProfile, logoutUser 
    } = useLogin(useShallow((state) => ({
        isAuthorized: state.isAuthorized,
        isLoading: state.isLoading,
        errorLogin: state.error,

        userLastName: state.profile.lastName,
        userFirstName: state.profile.firstName,
        userSecondName: state.profile.secondName,

        checkUserAuth: state.checkUserAuth,
        getUserProfile: state.getUserProfile,
        logoutUser: state.logoutUser
    })))

    const [theme, setTheme] = useState('light-theme')

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const drawerMenu = useInput<IDrawerMenu>({isOpen: false})

    
    useEffect(() => { // Работа с темой
        if (!localStorage.getItem('theme')) {
            localStorage.setItem('theme', 'light-theme')
        } else {
            setTheme(`${localStorage.getItem('theme')}`)
            localStorage.setItem('theme', `${localStorage.getItem('theme')}`)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // При изменении темы
        document.documentElement.className = localStorage.getItem('theme') || ''
    }, [theme]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // Проверка авторизации
        if (localStorage.getItem('us_id') && localStorage.getItem('us_exp')) {
            checkUserAuth().then((res) => {
                if (res) {
                    getUserProfile()
                } else {
                    navigate('/')
                }
            })
        } else {
            resetStore()
            navigate('/')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // Вывод ошибок
        if (errorLogin) return notifyOpen(errorLogin?.message, errorLogin?.type, 2000, notify)
    }, [errorLogin]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Изменение темы */
    const toggleTheme = () => {
        const newTheme = localStorage.getItem('theme') === 'light-theme' ? 'dark-theme' : 'light-theme'
        localStorage.setItem('theme', newTheme)
        setTheme(newTheme)
    }

    /** Открытие/закрытие окна подтверждения */
    const handlerOpenModalConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText, 
            handlerButtonCancel() {modalConfirm.setValue({isOpen: false})}, 
            handlerButtonOk() {
                logoutUser()
                navigate('/')
            }
        })
    }
    
    /** Открытие/закрытие главного мобильного меню */
    const handlerOpenDrawerMenu = (isOpen: boolean) => {
        drawerMenu.setValue({
            isOpen,
            handlerButtonCancel() { drawerMenu.setValue({ isOpen: false }) },
            handlerOpenModalExitProgram() {
                handlerOpenModalConfirm(true, 'Выход из системы...', 'Вы действительно хотите выйти из приложения?')
            }
        })
    }
    
    /** Свойства передаваемые в компоненту */
    const propsToUI: IMainInterface = {
        userLastName, userFirstName, userSecondName,
        handlerOpenModalConfirm, handlerOpenDrawerMenu, toggleTheme
    }

    return <>
        {isLoading 
            ? <Loader isOpen={isLoading} color='#F34E2E' title='Загрузка...' />
            : <MainInterface {...propsToUI} />
        }

        <DrawerMenu {...drawerMenu.value} />
        <ModalConfirm {...modalConfirm.value} />
    </>
}