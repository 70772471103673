import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { ICompaniesActions, ICompaniesState, ICompaniesStatistics, ICompanyCard, ICompanyCardSave, IResponseCompanies, IResponseCompanyCard, IResponseFetchSuggestions } from './_types'

import api, { handleRequest } from 'services/api.service'

import { IResponseCommon } from 'store/types.common'
import { IResponsePersonsStatistics } from 'store/persons/_types'


const route = '/companies'


/** Начальное состояние  */
export const initialStateCompanies: ICompaniesState = {
    error: null,
    companiesStatistics: {} as ICompaniesStatistics,

    companies: {
        isLoading: false,
        isReload: false,
        items: [],

        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },

    companyCard: {
        isLoading: false,
        items: {} as ICompanyCard
    }
}

/** Хранилище страницы - Контрагенты */
export const useCompanies = create<ICompaniesState & ICompaniesActions>()(devtools((set) => ({
    ...initialStateCompanies,

    /** Установка хранилища до начального состояния */
    clearCompaniesState: () => { set(initialStateCompanies) },
    /** Очистка данных по анкете контрагента */
    clearCompanyCard: () => { set((state) => ({ companyCard: { ...state.companyCard, items: {} as ICompanyCard } })) },


    /** Получение списка контрагенты */
    getCompanies: async (pageNumber: number, search: string, isCompany?: number) => {
        set((state) => ({ companies: { ...state.companies, isLoading: !state.companies.isReload } }))

        const res = await handleRequest(api.get<IResponseCompanies>(`${route}/getCompanies`, { params: { pageNumber, search, isCompany } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        const resStat = await handleRequest(api.get<IResponsePersonsStatistics>(`${route}/getCompaniesStatistics`))
        if (resStat.status !== 200) set({ error: { type: resStat.status === 500 ? 'error' : 'warning', message: resStat.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ companies: { ...state.companies, isReload: false } }))

            set((state) => ({
                companiesStatistics: {
                    ...state.companiesStatistics,
                    ...resStat.data
                },
                companies: {
                    ...state.companies, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({companies: {...state.companies, isLoading: false}}))

        return res
    },

    /** Получение данных по анкете контрагента */
    getCompanyCard: async (companyID: string) => {
        set((state) => ({ companyCard: { ...state.companyCard, isLoading: true } }))

        const res = await handleRequest(api.get<IResponseCompanyCard>(`${route}/getCompanyCard`, { params: { companyID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({
                companyCard: {
                    ...state.companyCard, 
                    items: res.data || {} as ICompanyCard
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ companyCard: { ...state.companyCard, isLoading: false } }))

        return res
    },

    /** Сохранение данных по анкете контрагента */
    setCompanyCardSave: async (companyID: string, cardData: ICompanyCardSave) => {
        set((state) => ({ companyCard: { ...state.companyCard, isLoading: true } }))

        const res = await handleRequest(api.put<IResponseCommon>(`${route}/setCompanyCardSave?companyID=${companyID}`, { companyData: JSON.stringify(cardData) }, { headers: { "Content-Type": `multipart/form-data` } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ companies: { ...state.companies, isReload: true } }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ companyCard: { ...state.companyCard, isLoading: false } }))

        return res
    },

    /** Проверка контрагента по ИНН и КПП и возврат наименования */
    getFetchSuggestions: async (inn: string, kpp: string) => {
        const res = await handleRequest(api.get<IResponseFetchSuggestions>(`${route}/getFetchSuggestions`, { params: { inn, kpp } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
        }

        setTimeout(() => { set({ error: null }) }, 2000)

        return res
    },
    
}), { name: 'companiesState' }))