import { NavLink } from 'react-router-dom'

import { IMainInterface } from './_types'

import logo from 'images/logo.png'
import { IconCompany, IconExitApp, IconMenu, IconProfile, IconSettings, IconStudents, IconTheme, IconTransfer } from 'images/icons'

import { Button, Divider, Flex, Grid, ToolTip } from 'components/ui'
import MainRoutes from 'routes/main.routes'


/** Главная - Интерфейс */
export const MainInterface = ({
    userLastName, userFirstName, userSecondName,
    handlerOpenModalConfirm, handlerOpenDrawerMenu, toggleTheme
}: IMainInterface) => {
    return <>
        <Flex gap='2px' direction='row' className='main_container'>
            <Flex id='mainMenu' gap='25px' alignItems='center' className='main_container-nav'>
                <img src={logo} alt="Логотип организации" />

                <Flex gap='20px' alignItems='center' justifyContent='center' className='main_container-nav_main'>
                    <NavLink to='persons' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Выпускники">
                        <IconStudents />
                    </NavLink>

                    <NavLink to='companies' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Контрагенты">
                        <IconCompany />
                    </NavLink>

                    <NavLink to='transfer' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Передача данных">
                        <IconTransfer />
                    </NavLink>
 
                    <NavLink to='settings' className={({ isActive }) => isActive ? 'nav_button nav_button-active' : 'nav_button'} data-tooltip-id="navTooltip" data-tooltip-content="Администрирование">
                        <IconSettings />
                    </NavLink>
                </Flex>

                <Flex gap='6px' alignItems='center' justifyContent='center'>
                    <Button id="tooltipBtnExit" variant='icon' size='md' onClick={() => handlerOpenModalConfirm(true, 'Выход из системы...', 'Вы действительно хотите выйти из приложения?')}>
                        <IconExitApp />
                    </Button>
                </Flex>
            </Flex>

            <Flex flexGrow={1}>
                <Grid gap='10px' alignItems='center' gridTemplate='columns' gridTemplateColumns='1fr auto' padding='6px 6px 0px 6px'>
                    <div id='mobileMenu'>
                        <Button size='sm' variant='icon' onClick={() => handlerOpenDrawerMenu(true)}>
                            <IconMenu />
                        </Button>
                    </div>

                    <Flex gap='10px' direction='row' alignItems='center' justifyContent='flex-end'>
                        {/* <Button id="tooltipBtnMessage" variant='icon' size='sm'>
                            <IconMessage />
                        </Button> */}

                        <Button id="tooltipBtnProfile" variant='icon' size='sm' padding='0 0 0 10px'>
                            <Flex gap='5px' direction='row' alignItems='center'>
                                <p>{`${userLastName && userLastName} ${userFirstName && userFirstName.substring(0, 1)}.${userSecondName && userSecondName.substring(0, 1)}.`}</p>
                                <IconProfile />
                            </Flex>
                        </Button>

                        <Divider type='vertical' variant='light' size='1.5em' />

                        <Button id="tooltipBtnTheme" variant='icon' size='sm' onClick={toggleTheme}>
                            <IconTheme />
                        </Button>
                    </Flex>
                </Grid>
                
                <main className='main_container-body'>
                    <MainRoutes />
                </main>
            </Flex>
        </Flex>

        <ToolTip id="navTooltip" place="right" />
        <ToolTip anchorSelect="#tooltipBtnMessage" content='Сообщения' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnProfile" content='Мой профиль' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnTheme" content='Изменить тему' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnExit" content='Выход' place="right" />
    </>
}