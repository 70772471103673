import moment from "moment"
import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardEmploymentUI } from "./_interface"
import { FieldsErrorType, ICardEmploymentContainer, ICardEmploymentUI, IFieldsError } from "./_types"

import { useInput } from "hooks/useInput"
import { validFormData } from "hooks/useForm"

import { usePersons } from "store/persons"

import { IModalCalendar, ModalCalendar } from "components/dumb/modal.calendar"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"

import { ISelectionCompanyContainer, SelectionCompanyContainer } from "../selection_company"
import { DictNameType, ISelectionDictionaryContainer, SelectionDictionaryContainer } from "../selection_dictionary"
import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"


/** Анкета по трудоустройству физического лица - Контейнер */
export const CardEmploymentContainer = ({ isOpen, cardMode, cardID, personID, isDead, personFIO, handlerButtonOk, handleButtonCancel }: ICardEmploymentContainer) => {
    const notify = useContext(NotificationContext)

    const fieldsError = useInput<IFieldsError>({})
    const isDisabledButtonSave = useInput<boolean>(true)
    const loadTitle = useInput<string>('Загрузка данных...')

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalCalendar = useInput<IModalCalendar>({isOpen: false})
    const modalDictionary = useInput<ISelectionDictionaryContainer>({isOpen: false})
    const modalSelectionCompany = useInput<ISelectionCompanyContainer>({isOpen: false})

    const txtEmploymentStatusID = useInput<number | ''>(5)
    const txtEmploymentStatusName = useInput<string>('Нетрудоустроен')
    const txtEmploymentStatusIsConfirmed = useInput<boolean>(false)
    const txtEmploymentStatusStartDate = useInput<string>('')
    const txtEmploymentStatusEndDate = useInput<string>('')
    const txtReasonForUnemploymentID = useInput<number | ''>('')
    const txtReasonForUnemploymentName = useInput<string>('')

    const txtCompanyID = useInput<string>('')
    const txtCompanyName = useInput<string>('')
    const txtIsForeignCompany = useInput<boolean>(false)
    const txtIsProvidedLivingSpace = useInput<boolean>(false)
    const txtPosition = useInput<string>('')
    
    const txtIsEmployedBySpeciality = useInput<boolean>(false)
    const txtIsEmployedOnTerritorySubject = useInput<boolean>(false)
    const txtIsMain = useInput<boolean>(false)
    const txtEstimatedSalary = useInput<string>('0')
    const txtEmploymentDurationID = useInput<number | ''>('')
    const txtEmploymentDurationName = useInput<string>('')


    /** Хранилище - Физические лица */
    const { 
        isLoadingCard, cardData, clearCardData, getCardData, saveCardData,
    } = usePersons(useShallow((state) => ({
        isLoadingCard: state.personEmploymentCard.isLoading,
        cardData: state.personEmploymentCard.items,

        clearCardData: state.clearPersonEmploymentCard,
        getCardData: state.getPersonEmploymentCard,
        saveCardData: state.setPersonEmploymentCardSave
    })))


    useEffect(() => {
        if (isOpen) {
            if (cardMode === 'edit') { loadData() } 
            else { txtEmploymentStatusStartDate.setValue(`${moment(new Date()).format('DD.MM.YYYY')}`) }
        } else if (!isOpen) {
            clearCardData()
            loadTitle.setValue('Загрузка данных...')

            txtEmploymentStatusID.setValue(5)
            txtEmploymentStatusName.setValue('Нетрудоустроен')
            txtEmploymentStatusIsConfirmed.setValue(false)
            txtEmploymentStatusStartDate.setValue('')
            txtEmploymentStatusEndDate.setValue('')
            txtReasonForUnemploymentID.setValue(8)
            txtReasonForUnemploymentName.setValue('Иные причины')
        
            txtCompanyID.setValue('')
            txtCompanyName.setValue('')
            txtIsForeignCompany.setValue(false)
            txtIsProvidedLivingSpace.setValue(false)
            txtPosition.setValue('')
            
            txtIsEmployedBySpeciality.setValue(false)
            txtIsEmployedOnTerritorySubject.setValue(false)
            txtIsMain.setValue(false)
            txtEstimatedSalary.setValue('0')
            txtEmploymentDurationID.setValue('')
            txtEmploymentDurationName.setValue('')
        }
    }, [ isOpen ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen) {
            /** Условия для активации кнопки (Сохранить изменения) */
            const isFormChanged = (
                txtEmploymentStatusID.value !== cardData.employmentStatusID ||
                txtEmploymentStatusIsConfirmed.value !== (cardData.isConfirmed === 1 ? true : false) || 
                txtEmploymentStatusStartDate.value !== cardData.startDate ||
                txtEmploymentStatusEndDate.value !== (cardData.endDate || '') ||
                txtReasonForUnemploymentID.value !== (cardData.reasonForUnemploymentID || '') ||
                txtCompanyID.value !== (cardData.companySourceGuid || '') ||
                txtIsForeignCompany.value !== (cardData.isForeignCompany === 1 ? true : false) ||
                txtIsProvidedLivingSpace.value !== (cardData.isProvidedLivingSpace === 1 ? true : false) || 
                txtPosition.value !== (cardData.position || '') ||
                txtIsEmployedBySpeciality.value !== (cardData.isEmployedBySpeciality === 1 ? true : false) || 
                txtIsEmployedOnTerritorySubject.value !== (cardData.isEmployedOnTerritorySubject === 1 ? true : false) || 
                txtIsMain.value !== (cardData.isMain === 1 ? true : false) ||
                txtEstimatedSalary.value !== (cardData.estimatedSalary.toString() || '0') ||
                txtEmploymentDurationID.value !== (cardData.employmentDurationID || '')
            )

            /** Активируем кнопку сохранить изменения, в случае если одно из условий выполнено */
            if (isFormChanged) { isDisabledButtonSave.setValue(false) }
            else { isDisabledButtonSave.setValue(true) } 
        }
    }, [ 
        isOpen, isDisabledButtonSave,

        txtEmploymentStatusID.value, txtEmploymentStatusIsConfirmed.value,
        txtEmploymentStatusStartDate.value, txtEmploymentStatusEndDate.value,
        txtReasonForUnemploymentID.value, txtCompanyID.value, txtIsForeignCompany.value,
        txtIsProvidedLivingSpace.value, txtPosition.value, txtIsEmployedBySpeciality.value, 
        txtIsEmployedOnTerritorySubject.value, txtIsMain.value, txtEstimatedSalary.value, txtEmploymentDurationID.value,

        cardData.employmentStatusID, cardData.isConfirmed, cardData.startDate, cardData.endDate, 
        cardData.reasonForUnemploymentID, cardData.companySourceGuid, cardData.isForeignCompany, 
        cardData.isProvidedLivingSpace, cardData.position, cardData.isEmployedBySpeciality, 
        cardData.isEmployedOnTerritorySubject, cardData.isMain, cardData.estimatedSalary, cardData.employmentDurationID
    ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && txtIsForeignCompany.value) {
            txtCompanyID.setValue('')
            txtCompanyName.setValue('')
        } else if (isOpen && !txtIsForeignCompany.value) {
            txtCompanyID.setValue(`${cardData.companySourceGuid || ''}`)
            txtCompanyName.setValue(`${cardData.companyName || ''}`)
        }
    }, [isOpen, txtIsForeignCompany.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && cardMode === 'new') {
            switch (txtEmploymentStatusID.value) {
                case 1: { // Военный по контракту
                    txtEmploymentStatusIsConfirmed.setValue(false)
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(true)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }

                case 2: { // Трудоустроен
                    txtEmploymentStatusIsConfirmed.setValue(false)
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(false)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }

                case 3: { // Индивидуальный предприниматель
                    txtEmploymentStatusIsConfirmed.setValue(false)
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(false)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }

                case 4: { // Самозанятый
                    txtEmploymentStatusIsConfirmed.setValue(false)
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(false)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }

                case 5: { // Нетрудоустроен
                    txtReasonForUnemploymentID.setValue(8)
                    txtReasonForUnemploymentName.setValue('Иные причины')
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(false)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }
            }
        } else if (isOpen && cardMode === 'edit') {
            switch (txtEmploymentStatusID.value) {
                case 1: { // Военный по контракту
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(true)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }

                case 2: { // Трудоустроен
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                    break
                }

                case 3: { // Индивидуальный предприниматель
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    break
                }

                case 4: { // Самозанятый
                    txtReasonForUnemploymentID.setValue('')
                    txtReasonForUnemploymentName.setValue('')
                
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    break
                }

                case 5: { // Нетрудоустроен
                    txtCompanyID.setValue('')
                    txtCompanyName.setValue('')
                    txtIsForeignCompany.setValue(false)
                    txtIsProvidedLivingSpace.setValue(false)
                    txtPosition.setValue('')
                    
                    txtIsEmployedBySpeciality.setValue(false)
                    txtIsEmployedOnTerritorySubject.setValue(false)
                    txtIsMain.setValue(false)
                    txtEstimatedSalary.setValue('0')
                    txtEmploymentDurationID.setValue('')
                    txtEmploymentDurationName.setValue('')
                    break
                }
            }
        }
    }, [isOpen, txtEmploymentStatusID.value]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Загрузка данных */
    const loadData = () => {
        getCardData(`${cardID}`).then((res) => {
            if (res.status === 200) {
                txtEmploymentStatusID.setValue(res.data?.employmentStatusID || '')
                txtEmploymentStatusName.setValue(`${res.data?.employmentStatusName}`)
                txtEmploymentStatusIsConfirmed.setValue(res.data?.isConfirmed === 1 ? true : false)
                txtEmploymentStatusStartDate.setValue(`${res.data?.startDate}`)
                txtEmploymentStatusEndDate.setValue(`${res.data?.endDate || ''}`)
                txtReasonForUnemploymentID.setValue(res.data?.reasonForUnemploymentID || '')
                txtReasonForUnemploymentName.setValue(`${res.data?.reasonForUnemploymentName || ''}`)
            
                txtCompanyID.setValue(`${res.data?.companySourceGuid || ''}`)
                txtCompanyName.setValue(`${res.data?.companyName || ''}`)
                txtIsForeignCompany.setValue(res.data?.isForeignCompany === 1 ? true : false)
                txtIsProvidedLivingSpace.setValue(res.data?.isProvidedLivingSpace === 1 ? true : false)
                txtPosition.setValue(`${res.data?.position || ''}`)
                
                txtIsEmployedBySpeciality.setValue(res.data?.isEmployedBySpeciality === 1 ? true : false)
                txtIsEmployedOnTerritorySubject.setValue(res.data?.isEmployedOnTerritorySubject === 1 ? true : false)
                txtIsMain.setValue(res.data?.isMain === 1 ? true : false)
                txtEstimatedSalary.setValue(`${res.data?.estimatedSalary || '0'}`)
                txtEmploymentDurationID.setValue(res.data?.employmentDurationID || '')
                txtEmploymentDurationName.setValue(`${res.data?.employmentDurationName || ''}`)
            }
        })
    }



    /** Открытие/закрытие окна подтверждения */
    const handleOpenConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText,
            handlerButtonCancel() { modalConfirm.setValue({ isOpen: false }) },
            handlerButtonOk() {
                modalConfirm.setValue({ isOpen: false })
                handleButtonCancel && handleButtonCancel()
            }
        })
    }
    /** Открытие/закрытие окна выбора даты в календаре */
    const handleOpenCalendar = (isOpen: boolean, fieldName: string, currentDate?: string) => {
        modalCalendar.setValue({
            isOpen, currentDate,
            handlerButtonCancel() {modalCalendar.setValue({isOpen: false})}, 
            handlerButtonOk(stringDate) {
                switch (fieldName) {
                    case 'employmentStatusStartDate': txtEmploymentStatusStartDate.setValue(stringDate); break
                    case 'employmentStatusEndDate': txtEmploymentStatusEndDate.setValue(stringDate); break
                }
                modalCalendar.setValue({isOpen: false})
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenDictionary = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDictionary.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() { modalDictionary.setValue({ isOpen: false }) },
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'employmentStatus': { // Статус трудоустройства
                        txtEmploymentStatusID.setValue(Number(id))
                        txtEmploymentStatusName.setValue(name)
                        break
                    }

                    case 'reasonForUnemployment': { // Причина нетрудоустройства
                        txtReasonForUnemploymentID.setValue(Number(id))
                        txtReasonForUnemploymentName.setValue(name)
                        break
                    }

                    case 'employmentDuration': { // Продолжительность трудоустройства
                        txtEmploymentDurationID.setValue(Number(id))
                        txtEmploymentDurationName.setValue(name)
                        break
                    }
                }

                modalDictionary.setValue({ isOpen: false })
            }
        })
    }
    /** Открытие/закрытие окна выбора контрагента */
    const handlerOpenSelectionCompany = (isOpen: boolean) => {
        modalSelectionCompany.setValue({
            isOpen, // isCompany: txtEmploymentStatusID.value === 2 ? 1 : 0,
            handlerButtonCancel() {modalSelectionCompany.setValue({isOpen: false})}, 
            handlerButtonOk(companyID: string, companyName: string) {
                txtCompanyID.setValue(companyID)
                txtCompanyName.setValue(companyName)

                modalSelectionCompany.setValue({isOpen: false})
            }
        })
    }



    /** Изменение поля - Ожидаемый оклад */
    const handleChangeEstimatedSalary = (value: string) => {
        // Если value пусто, присваиваем '0'
        if (!value) {
            txtEstimatedSalary.setValue('0')
            return
        }
    
        // Если value не состоит только из цифр, выходим
        if (!/^\d+$/.test(value)) return
    
        // Если значение начинается с '0' и содержит больше одной цифры, убираем первый '0'
        if (value.length > 1 && value[0] === '0') {
            value = value.slice(1)
        }
    
        txtEstimatedSalary.setValue(value)
    }
    


    /** Кнопка создать/сохранить в UI анкеты */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            {fieldName: 'employmentStatus', value: txtEmploymentStatusName.value, validType: 'notEmpty', isRequired: true, errorMessage: 'Статус трудоустройства некорректное значение.'},
            {fieldName: 'employmentStatusStartDate', value: txtEmploymentStatusStartDate.value, validType: 'date', isRequired: true, errorMessage: 'Дата начала статуса некорректное значение.'},
            {fieldName: 'employmentStatusEndDate', value: txtEmploymentStatusEndDate.value, validType: 'date', isRequired: txtEmploymentStatusEndDate.value.length > 0, errorMessage: 'Дата окончания статуса некорректное значение.'},
            {fieldName: 'reasonForUnemploymentName', value: txtReasonForUnemploymentName.value, validType: 'notEmpty', isRequired: [5].includes(txtEmploymentStatusID.value || 0), errorMessage: 'Причина не трудоустройства некорректное значение.'},
            
            {fieldName: 'companyName', value: txtPosition.value, validType: 'notEmpty', isRequired: [2, 3].includes(txtEmploymentStatusID.value || 0), errorMessage: 'Наименование контрагента некорректное значение.'},
            {fieldName: 'position', value: txtPosition.value, validType: 'notEmpty', isRequired: [2].includes(txtEmploymentStatusID.value || 0), errorMessage: 'Занимаемая должность некорректное значение.'},
            {fieldName: 'estimatedSalary', value: txtEstimatedSalary.value, validType: 'notEmpty', isRequired: [2, 3, 4].includes(txtEmploymentStatusID.value || 0), errorMessage: 'Ожидаемый оклад некорректное значение.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            loadTitle.setValue('Сохраняю данные анкеты...')

            saveCardData(cardID || '', {
                actionForm: cardMode || 'new',
            
                personSourceGuid: personID || '',
                companySourceGuid: txtCompanyID.value,
                isForeignCompany: txtIsForeignCompany.value ? 1 : 0,
                position: txtPosition.value,
                isEmployedBySpeciality: txtIsEmployedBySpeciality.value ? 1 : 0,
                isEmployedOnTerritorySubject: txtIsEmployedOnTerritorySubject.value ? 1 : 0,
                isProvidedLivingSpace: txtIsProvidedLivingSpace.value ? 1 : 0,
                startDate: txtEmploymentStatusStartDate.value,
                endDate: txtEmploymentStatusEndDate.value,
                isMain: txtIsMain.value ? 1 : 0,
                employmentDurationID: txtEmploymentDurationID.value,
                employmentStatusID: txtEmploymentStatusID.value,
                reasonForUnemploymentID: txtReasonForUnemploymentID.value,
                isConfirmed: txtEmploymentStatusIsConfirmed.value ? 1 : 0,
                estimatedSalary: txtEstimatedSalary.value
            }).then((res) => {
                if (res.status === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Кнопка отмена в UI анкеты */
    const handleBtnCancel = () => {
        if (isDisabledButtonSave.value) {
            handleButtonCancel && handleButtonCancel()
        } else {
            handleOpenConfirm(true, 'Сохранение формы', '<b>Внимание!</b> У Вас имеются не сохранённые данные по текущей форме!<br/><br/>Вы действительно хотите закрыть текущее окно, без сохранения внесённых Вами изменений?')
        }
    } 
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => { fieldsError.setValue(prevState => ({ ...prevState, [field]: !prevState[field] })) }, 400)
        setTimeout(() => { fieldsError.setValue({ [field]: false }); clearInterval(interval) }, 8000)
    }



    /** Свойства передаваемые в компоненту */
    const propsToUI: ICardEmploymentUI = {
        isOpen, cardMode, isDead, personFIO, isDisabledButtonSave, 
        fieldsError, loadTitle, isLoadingCard,

        txtEmploymentStatusID, txtEmploymentStatusName,
        txtEmploymentStatusStartDate, txtEmploymentStatusEndDate,
        txtEmploymentStatusIsConfirmed, txtReasonForUnemploymentName,

        txtCompanyName, txtIsForeignCompany, txtPosition, txtIsProvidedLivingSpace,

        txtIsEmployedBySpeciality, txtIsEmployedOnTerritorySubject, 
        txtIsMain, txtEstimatedSalary, txtEmploymentDurationName,

        handleChangeEstimatedSalary, handlerOpenSelectionCompany,
        handleOpenCalendar, handleOpenDictionary,
        handleBtnSave, handleBtnCancel
    }
    
    return <>
        <CardEmploymentUI { ...propsToUI } />

        <ModalConfirm {...modalConfirm.value} />
        <ModalCalendar {...modalCalendar.value} />
        <SelectionDictionaryContainer {...modalDictionary.value} />
        <SelectionCompanyContainer {...modalSelectionCompany.value} />
    </>
}