import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { CardEducationUI } from "./_interface"
import { FieldsErrorType, ICardEducationContainer, ICardEducationUI, IFieldsError } from "./_types"

import { useInput } from "hooks/useInput"
import { validFormData } from "hooks/useForm"

import { usePersons } from "store/persons"

import { DictNameType, ISelectionDictionaryContainer, SelectionDictionaryContainer } from "../selection_dictionary"
import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"
import { IModalConfirm, ModalConfirm } from "components/dumb/modal.confirm"


/** Анкета по образованию физического лица - Контейнер */
export const CardEducationContainer = ({ isOpen, cardID, isDead, personFIO, handlerButtonOk, handleButtonCancel }: ICardEducationContainer) => {
    const notify = useContext(NotificationContext)

    const fieldsError = useInput<IFieldsError>({})
    const isDisabledButtonSave = useInput<boolean>(true)
    const loadTitle = useInput<string>('Загрузка данных...')

    const modalConfirm = useInput<IModalConfirm>({isOpen: false})
    const modalDictionary = useInput<ISelectionDictionaryContainer>({isOpen: false})

    const txtYear = useInput<string>('')
    const txtFaculty = useInput<string>('')
    const txtStartEducationDate = useInput<string>('')
    const txtEndEducationDate = useInput<string>('')
    const txtCourse = useInput<string>('')
    const txtEducationForm = useInput<string>('')
    const txtEducationLevel = useInput<string>('')
    const txtFinanceType = useInput<string>('')
    const txtSpecialityName = useInput<string>('')
    const txtSpecializationName = useInput<string>('')
    const txtStatus = useInput<string>('')
    const txtDegreeDate = useInput<string>('')
    const txtIsTargetDirection = useInput<string>('Нет')
    const txtIsProfessionalProgram = useInput<boolean>(false)



    /** Хранилище - Физические лица */
    const { 
        isLoadingCard, cardData, clearCardData, getCardData, saveCardData,
    } = usePersons(useShallow((state) => ({
        isLoadingCard: state.personEducationCard.isLoading,
        cardData: state.personEducationCard.items,

        clearCardData: state.clearPersonEducationCard,
        getCardData: state.getPersonEducationCard,
        saveCardData: state.setPersonEducationCardSave
    })))

    useEffect(() => {
        if (isOpen) {
            loadData()
        } else if (!isOpen) {
            clearCardData()
            loadTitle.setValue('Загрузка данных...')

            txtYear.setValue(``)
            txtFaculty.setValue(``)
            txtStartEducationDate.setValue(``)
            txtEndEducationDate.setValue(``)
            txtCourse.setValue(``)
            txtEducationForm.setValue(``)
            txtEducationLevel.setValue(``)
            txtFinanceType.setValue(``)
            txtSpecialityName.setValue(``)
            txtSpecializationName.setValue(``)
            txtStatus.setValue(``)
            txtDegreeDate.setValue(``)
            txtIsTargetDirection.setValue('Нет')
            txtIsProfessionalProgram.setValue(false)
        }
    }, [ isOpen ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen) {           
            /** Условия для активации кнопки (Сохранить изменения) */
            const isFormChanged = (
                txtIsProfessionalProgram.value !== (cardData.isProfessionalProgram === 1 ? true : false)
            )

            /** Активируем кнопку сохранить изменения, в случае если одно из условий выполнено */
            if (isFormChanged) { isDisabledButtonSave.setValue(false) }
            else { isDisabledButtonSave.setValue(true) } 
        }
    }, [ isOpen, txtIsProfessionalProgram.value ]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Загрузка данных */
    const loadData = () => {
        getCardData(`${cardID}`).then((res) => {
            if (res.status === 200) {
                txtYear.setValue(`${res.data?.year}`)
                txtFaculty.setValue(`${res.data?.faculty}`)
                txtStartEducationDate.setValue(`${res.data?.startEducationDate}`)
                txtEndEducationDate.setValue(`${res.data?.endEducationDate}`)
                txtCourse.setValue(`${res.data?.course}`)
                txtEducationForm.setValue(`${res.data?.educationForm}`)
                txtEducationLevel.setValue(`${res.data?.educationLevel}`)
                txtFinanceType.setValue(`${res.data?.financeType}`)
                txtSpecialityName.setValue(`${res.data?.specialityCode} - ${res.data?.specialityName}`)
                txtSpecializationName.setValue(`${res.data?.specializationName}`)
                txtStatus.setValue(`${res.data?.status}`)
                txtDegreeDate.setValue(`${res.data?.degreeDate}`)
                txtIsTargetDirection.setValue(`${res.data?.isTargetDirection === 1 ? 'Да' : 'Нет'}`)
                txtIsProfessionalProgram.setValue(res.data?.isProfessionalProgram === 1 ? true : false)
            }
        })
    }



    /** Открытие/закрытие окна подтверждения */
    const handleOpenConfirm = (isOpen: boolean, headerText: string, contentText: string) => {
        modalConfirm.setValue({
            isOpen, headerText, contentText,
            handlerButtonCancel() { modalConfirm.setValue({ isOpen: false }) },
            handlerButtonOk() {
                modalConfirm.setValue({ isOpen: false })
                handleButtonCancel && handleButtonCancel()
            }
        })
    }
    /** Открытие/закрытие окна выбора справочника */
    const handleOpenDictionary = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDictionary.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() { modalDictionary.setValue({ isOpen: false }) },
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                // switch (dictName) {
                //     case 'citizenship': { // Гражданство
                //         txtCitizenshipID.setValue(Number(id))
                //         txtCitizenshipName.setValue(name)
                //         break
                //     }

                //     case 'disabilityGroup': { // Группа инвалидности
                //         txtDisabilityGroupID.setValue(Number(id))
                //         txtDisabilityGroupName.setValue(name)
                //         break
                //     }
                // }

                modalDictionary.setValue({ isOpen: false })
            }
        })
    }



    /** Кнопка создать/сохранить в UI анкеты */
    const handleBtnSave = () => {
        const validateData = validFormData([ // Валидируем данные 
            // {fieldName: 'companyINN', value: txtInn.value, validType: 'inn', isRequired: true, errorMessage: 'ИНН не корректное значение.'},
            // {fieldName: 'companyKPP', value: txtSnils.value, validType: 'snils', isRequired: txtCitizenshipID.value === 185, errorMessage: 'СНИЛС не корректное значение.'},
            
            // {fieldName: 'companyPhone', value: txtPhone.value, validType: 'phoneMask', isRequired: true, errorMessage: 'Номер телефона не корректный.'},
            // {fieldName: 'companyEmail', value: txtEmail.value, validType: 'email', isRequired: true, errorMessage: 'Электронная почта не корректная.'},
        ])

        for (const item of validateData) { // Выводим ошибки валидации
            fieldsErrorAnumation(item.fieldName as FieldsErrorType)
            notify && notifyOpen(item.message, 'warning', 2000, notify)
        }

        if (validateData.length === 0) { // Сохраняем данные
            loadTitle.setValue('Сохраняю данные анкеты...')

            saveCardData(cardID || '', {
                isProfessionalProgram: txtIsProfessionalProgram.value ? 1 : 0
            }).then((res) => {
                if (res.status === 200) {
                    notify && notifyOpen('Данные успешно сохранены.', 'success', 1500, notify)
                    handlerButtonOk && handlerButtonOk()
                }
            })
        }
    }
    /** Кнопка отмена в UI анкеты */
    const handleBtnCancel = () => {
        if (isDisabledButtonSave.value) {
            handleButtonCancel && handleButtonCancel()
        } else {
            handleOpenConfirm(true, 'Сохранение формы', '<b>Внимание!</b> У Вас имеются не сохранённые данные по текущей форме!<br/><br/>Вы действительно хотите закрыть текущее окно, без сохранения внесённых Вами изменений?')
        }
    } 
    /** Активация анимации ошибки в указанных полях */
    const fieldsErrorAnumation = (field: FieldsErrorType) => {
        const interval = setInterval(() => { fieldsError.setValue(prevState => ({ ...prevState, [field]: !prevState[field] })) }, 400)
        setTimeout(() => { fieldsError.setValue({ [field]: false }); clearInterval(interval) }, 8000)
    }



    /** Свойства передаваемые в компоненту */
    const propsToUI: ICardEducationUI = {
        isOpen, isDisabledButtonSave,
        loadTitle, isLoadingCard,

        isDead, personFIO,

        txtYear, txtFaculty, txtStartEducationDate, txtEndEducationDate,
        txtCourse, txtEducationForm, txtEducationLevel, txtFinanceType,

        txtSpecialityName, txtSpecializationName, txtStatus,
        txtDegreeDate, txtIsTargetDirection, txtIsProfessionalProgram,  

        handleOpenDictionary, handleBtnSave, handleBtnCancel
    }
    
    return <>
        <CardEducationUI { ...propsToUI } />

        <ModalConfirm {...modalConfirm.value} />
        <SelectionDictionaryContainer {...modalDictionary.value} />
    </>
}