import { ISelectionCompanyUI } from "./_types"

import imgEmty from 'images/empty.svg'
import { Button, Divider, Drawer, Empty, Flex, Input, Loader, Pagination, Table, ToolTip } from "components/ui"
import { IconAdd, IconClearClose, IconCompany, IconInfo, IconSearch, IconUpdate } from "images/icons"


/** Выбор контрагента - Интерфейс */
export const SelectionCompanyUI = ({ 
    isOpen, isLoading, txtSearch, companiesItems,
    totalCount, pageSize, currentPageNumber, companiesStatistics,
    loadDataList, txtSearchOnChange,
    handlerOpenCardCompany, handlerButtonOk, handlerButtonCancel 
}: ISelectionCompanyUI) => {
    return <Drawer isOpen={isOpen} placement='bottom' size='99.4%'>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconCompany color='#fff' width={20} height={20} />
                        <h4>Выбор контрагента</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Loader isOpen={isLoading} color='#f34e2e' title='Загрузка данных...' backdrop />

                <Flex gap="6px" direction="row">
                    <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                        value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                        paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                        suffix={<>
                            {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                            <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                        </>}
                    />

                    <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataList(txtSearch.value)}><IconUpdate /></Button>
                    <Button id='tooltipBtnAdd' size="sm" variant="icon" onClick={() => handlerOpenCardCompany(true, 'new')}><IconAdd /></Button>
                </Flex>

                <Divider variant="dark" />

                <Table.Container>
                    <Table.Header id='hiddenForMobile' classGridTemplateColumns="companies_grid_columns" marginLeft="54px" marginRight="20px">
                        <span>Наименование контрагента<br />ИНН / КПП</span>
                        <span>Телефон<br />Электронная почта</span>
                    </Table.Header>

                    <Table.Body>
                        {companiesItems.length === 0
                            ? <Empty image={imgEmty} title='Данные отсутствуют' />
                            : companiesItems.map((item, index) => (
                                <Table.Row key={item.companyID} indexContent={<span>{index + 1}</span>} indexContentWidth="44px" paddingInset="0 10px 0 10px"
                                    // badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}                                classGridTemplateColumns="applications_grid_columns" 
                                    classGridTemplateColumns="companies_grid_columns"
                                    onClick={() => handlerButtonOk && handlerButtonOk(item.companyID, item.companyName)}
                                >

                                    <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.companyName}</p>
                                        <p style={{ opacity: '.7' }}>{item.companyINN || <span style={{ color: 'red', fontWeight: 'bold' }}>ИНН ?</span>} / {item.companyKPP || <span style={{ color: 'red', fontWeight: 'bold' }}>----</span>}</p>
                                    </div>

                                    <div id='hiddenFor600' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                        <p>{item.companyPhone || <span style={{ color: 'red', fontWeight: 'bold' }}>телефон ?</span>}</p>
                                        <p style={{ opacity: '.7' }}>{item.companyEmail || <span style={{ color: 'red', fontWeight: 'bold' }}>email ?</span>}</p>
                                    </div>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table.Container>

                <Flex direction="row" justifyContent="flex-end" alignItems="center" className="page_container-footer">
                    <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
                </Flex>

                <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br />🗸 Наименованию<br />🗸 ИНН<br />🗸 КПП<br />🗸 Телефону<br />🗸 Email</ToolTip>
                <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
                <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
                <ToolTip anchorSelect="#tooltipBtnAdd" content='Создать' place="bottom-end" />
            </Flex>
        </Flex>
    </Drawer>
}