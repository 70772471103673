import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import css from './index.module.scss'
import { IPagination } from './type'
import { IconLeft, IconRight } from 'images/icons'
import { Button } from '../button'

/** Нумерация страниц */
export const Pagination = memo(({ totalCount = 0, pageSize = 0, currentPage = 0, size = 'sm', onChange }: IPagination): React.JSX.Element => {
    const panelRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleIsExpanded = () => {
        setIsExpanded((isExpanded) => !isExpanded)
    }

    const getBottomPosition = () => {
        switch (size) {
            case 'lg': return '55px'
            case 'md': return '45px'
            case 'sm': return '35px'
            case 'xs': return '25px'
            default: return '55px'
        }
    }

    const getDisabledButtons = () => {
        let btnLeft: boolean = currentPage === 1 ? true : false
        let btnRight: boolean = (totalCount / pageSize) <= currentPage ? true : false
        let btnPrimary: boolean = (totalCount / pageSize) <= 1 ? true : false

        if (totalCount === 0 || pageSize === 0) {
            btnLeft = true
            btnRight = true
            btnPrimary = true
        }

        return { btnLeft, btnRight, btnPrimary }
    }

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (paginationRef.current && !paginationRef.current.contains(event.target as Node)) {
            if (panelRef.current && !panelRef.current.contains(event.target as Node)) setIsExpanded(false)
        }
    }, [paginationRef, panelRef])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside])

    const panelStyles: React.CSSProperties = {
        bottom: getBottomPosition(),
        height: isExpanded ? '200px' : "0px",
    }

    return (
        <div className={css.pagination} ref={paginationRef}>
            <div className={css.pagination_list} style={panelStyles} ref={panelRef}>
                <div className={css.pagination_list__content}>
                    {Array.from({ length: Math.ceil(totalCount / pageSize) }, (_, index) => (
                        currentPage !== (index + 1) && (
                            <Button variant='icon' size='sm' key={index + 1} onClick={() => { onChange && onChange(index + 1); toggleIsExpanded() }}>Стр. {index + 1}</Button>
                        )
                    ))}
                </div>
            </div>

            <Button size={size} variant='iconBr' isDisabled={getDisabledButtons().btnLeft} onClick={() => { onChange && onChange(currentPage - 1) }}>
                <IconLeft width={16} height={16} />
            </Button>

            <Button size={size} variant='iconBr' padding='0 12px' isDisabled={getDisabledButtons().btnPrimary} onClick={() => toggleIsExpanded()}>
                Стр. {currentPage}
            </Button>

            <Button size={size} variant='iconBr' isDisabled={getDisabledButtons().btnRight} onClick={() => { onChange && onChange(currentPage + 1) }}>
                <IconRight width={16} height={16} />
            </Button>
        </div>
    )
})
