import React, { memo } from 'react'

import css from './index.module.scss'
import { IGrid } from './type'


/** Сетка для выравнивания контента. */
export const Grid = memo(({children, className, gap, gridTemplate, padding, gridTemplateColumns, gridTemplateRows, justifyContent, alignItems, onClick}: IGrid): React.JSX.Element  => {
    const getGridTemplate = () => {
        const gridTemplateColumnStyle: React.CSSProperties = {
            gap: gap,
            padding: padding,
            alignItems: alignItems,
            justifyContent: justifyContent,
            gridTemplateColumns: gridTemplateColumns,
        }

        const gridTemplateRowsStyle: React.CSSProperties = {
            gap: gap,
            padding: padding,
            alignItems: alignItems,
            justifyContent: justifyContent,
            gridTemplateRows: gridTemplateRows,
        }

        switch (gridTemplate) {
            case 'columns': return gridTemplateColumnStyle
            case 'rows': return gridTemplateRowsStyle
            default: return gridTemplateColumnStyle
        }
    }


    return <div style={getGridTemplate()} className={`${css.grid} ${className}`} onClick={onClick}>
        {children}
    </div>
})