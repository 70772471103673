import React, { memo } from 'react'
import { IFlex } from './type'
import css from './index.module.scss'


/** Контейнер гибкого макета для выравнивания. */
export const Flex = memo(({
    children, className,
    id, width, height, flexWrap,
    direction = 'column', gap = '0px', overflow, padding, color,
    justifyContent, alignItems, textAlign, flexGrow, flexBasis, isVisible = true
}: IFlex): React.JSX.Element => {
    const componentStyle: React.CSSProperties = {
        width: width,
        height: height,
        gap: gap,
        flexWrap: flexWrap,
        textAlign: textAlign,
        flexDirection: direction,
        alignItems: alignItems,
        justifyContent: justifyContent,
        flexGrow: flexGrow,
        flexBasis: flexBasis,
        overflowY: overflow,
        padding: padding,
        color: color
    }

    return <>
        {!isVisible ? ''
            : <div id={id} style={componentStyle} className={`${css.flex} ${className}`}>
                {children}
            </div>
        }
    </>
})