import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { LoginInterface } from './_interface'
import { ILoginInterface } from './_types'

import { useInput } from 'hooks/useInput'
import { useLogin } from 'store/login'
import { resetStore } from 'store/reset.store'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Аутентификация - Контейнер */
export const LoginContainer = () => {
    const notify = useContext(NotificationContext)

    const { isAuthorized, isLoading, error, loginUser, checkUserAuth } = useLogin(useShallow((state) => ({
        isAuthorized: state.isAuthorized,
        isLoading: state.isLoading,
        error: state.error,
        loginUser: state.loginUser,
        checkUserAuth: state.checkUserAuth
    })))

    const [theme, setTheme] = useState('light-theme')

    const txtLogin = useInput<string>('')
    const txtPassword = useInput<string>('')
    const isPassword = useInput<boolean>(false)


    useEffect(() => { // Работа с темой
        if (!localStorage.getItem('theme')) {
            localStorage.setItem('theme', 'light-theme')
        } else {
            setTheme(`${localStorage.getItem('theme')}`)
            localStorage.setItem('theme', `${localStorage.getItem('theme')}`)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // При изменении темы
        document.documentElement.className = localStorage.getItem('theme') || ''
    }, [theme]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // Проверка авторизации
        if (localStorage.getItem('us_id') && localStorage.getItem('us_exp')) {
            checkUserAuth()
        } else {
            resetStore()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // Вывод ошибок
        if (error) return notifyOpen(error?.message, error?.type, 2000, notify)
    }, [error]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Изменение темы */
    const toggleTheme = () => {
        const newTheme = localStorage.getItem('theme') === 'light-theme' ? 'dark-theme' : 'light-theme'
        localStorage.setItem('theme', newTheme)
        setTheme(newTheme)
    }

    /** Нажатие на кнопку войти в форме авторизации */
    const handlerButtonClickLogin = () => {
        if (!txtLogin.value || !txtPassword.value) return notifyOpen('В досутпе отказано.<br/>Необходимо ввести имя пользователя и его пароль.', 'warning', 1500, notify)
        loginUser(txtLogin.value, txtPassword.value)
    }

    /** Обработчик при нажатии клавиши Enter на полях имени пользователя и пароля */
    const handlerFormKeyEnterLogin = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') handlerButtonClickLogin()
    }


    /** Свойства передаваемые в компоненту */
    const propsToInterface: ILoginInterface = {
        isLoading,
        txtLogin, txtPassword, isPassword,
        handlerButtonClickLogin, handlerFormKeyEnterLogin, toggleTheme
    }

    return <>
        {isAuthorized
            ? <Navigate to="/lk" />
            : <LoginInterface {...propsToInterface} />
        }
    </>
}