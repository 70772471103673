import React, { ChangeEvent, memo, useEffect, useRef, useState } from 'react'
import { BeatLoader } from 'react-spinners'

import css from './index.module.scss'
import { IInput, InputSizeType } from './type'

/** Текстовое поле */
export const Input = memo(({ 
    id, name, type = 'text', placeholder, autoComplete = 'off', prefix, suffix, variant = 'primary', 
    mask, size, width, maxWidth, minWidth, isReadOnly = false, isDisabled = false, isLoading = false, 
    paddingLeft, paddingRight, value, onChange, textAlign = 'left', isVisible = true, maxLength
}: IInput): React.JSX.Element => {
    const [inputValue, setInputValue] = useState<string>('')
    const [cursorPos, setCursorPos] = useState<number | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // Синхронизация внутреннего состояния с внешним значением
        setInputValue(applyMask(value as string, mask as string))
    }, [value, mask])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const startPos = target.selectionStart || 0

        let val = target.value.replace(/\D/g, '') // Убираем все нецифровые символы
        let maskedVal = applyMask(val, mask as string)

        setInputValue(maskedVal)
        onChange && onChange(e) // Передаем значение маски наружу

        // Сохраняем позицию курсора
        const newCursorPos = calculateCursorPosition(startPos, maskedVal, mask as string)
        setCursorPos(newCursorPos)
    }

    useEffect(() => {
        // Восстанавливаем позицию курсора после обновления значения
        if (cursorPos !== null && inputRef.current) {
            inputRef.current.setSelectionRange(cursorPos, cursorPos)
        }
    }, [inputValue, cursorPos])

    function applyMask(value: string, mask: string = ''): string {
        let i = 0
        const val = value.replace(/\D/g, '')

        return mask.replace(/(?!\+)./g, (a) => {
            return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a
        })
    }
    
    function calculateCursorPosition(pos: number, maskedValue: string, mask: string): number {
        let numericIndex = 0

        for (let i = 0; i < mask.length && numericIndex < pos; i++) {
            if (mask[i] === '#') {
                numericIndex++
            } else {
                numericIndex += 2
            }
        }

        return numericIndex + 1
    }

    const getSizeClassName = (size?: InputSizeType) => {
        switch (size) {
            case 'lg': return css.lg
            case 'md': return css.md
            case 'sm': return css.sm
            case 'xs': return css.xs
            default: return css.md
        }
    }

    const inputType = (type: React.HTMLInputTypeAttribute | 'mask') => {
        switch (type) {
            case 'mask': return (
                <input  
                    id={id} 
                    name={name}
                    placeholder={placeholder} 
                    value={inputValue} 
                    onChange={handleInputChange} 
                    autoComplete={autoComplete} 
                    readOnly={isReadOnly} 
                    disabled={isDisabled} 
                    className={`${getSizeClassName(size)}`} 
                    ref={inputRef}
                />
            )
            
            default: return (
                <input 
                    id={id} 
                    name={name} 
                    type={type} 
                    maxLength={maxLength}
                    placeholder={placeholder} 
                    value={value?.toString()} 
                    onChange={onChange} 
                    autoComplete={autoComplete} 
                    readOnly={isReadOnly} 
                    disabled={isDisabled} 
                    className={`${getSizeClassName(size)}`} 
                    style={{textAlign: textAlign}} 
                />
            )
        }
    }

    return (
        <>
            {isVisible && <div className={`${css[variant]} ${getSizeClassName(size)} ${isDisabled && css['disabled']}`} onClick={(e) => e.stopPropagation()} style={{ width: width, maxWidth: maxWidth, minWidth, paddingRight: paddingRight, paddingLeft: paddingLeft }}>
                {prefix}
                {inputType(type)}
                {suffix}

                {isLoading && <div className={css.isLoading}><BeatLoader size={10} loading={isLoading} color='#f34e2e' /></div>}
            </div>}
        </>
    )
})
