import React, { memo } from 'react'

import { ITableContainer, ITableHeaders, ITableBody, ITableRow, ITableRowCard } from './type'
import css from './index.module.scss'
import { Loader } from './../loader'


/** Таблица - Контейнер */
export const TableContainer = memo(({isLoading, loadingTitle, children, className}: ITableContainer): React.JSX.Element=> {
    return <>
        <div className={`${css.table_container} ${className}`}>
            {isLoading && <Loader isOpen={isLoading} backdrop color='#f34e2e' title={loadingTitle} />}
            {children}
        </div>
    </>
})


/** Таблица - Заголовок */
export const TableHeaders = memo(({id, children, classGridTemplateColumns, marginLeft, marginRight}: ITableHeaders): React.JSX.Element=> {
    return (
        <div id={id} className={`${css.table_container__headers} ${classGridTemplateColumns}`} style={{ marginLeft: marginLeft, marginRight: marginRight }}>
            {children}
        </div>
    )
})


/** Таблица - Тело под строки */
export const TableBody = memo(({children, minHeight, overflowY = 'scroll', flexGrow}: ITableBody): React.JSX.Element=> {
    const componentStyle: React.CSSProperties = {
        overflow: 'hidden',
        overflowY: overflowY,
        paddingRight: overflowY === 'hidden' ? '0' : '4px',
        position: 'relative',
        minHeight: minHeight,
        flexGrow: flexGrow,
    }

    return (
        <div className={`${css.table_container__body}`} style={componentStyle}>
            {children}
        </div>
    )
})


/** Таблица - Строка с данными */
export const TableRow = memo(({
    myKey, indexContent, indexContentWidth, isVisible = true, indexContentTextAlign = 'center', indexContentPadding, badge, children, classGridTemplateColumns, backgroundColor, backgroundColorInset, paddingInset, onClick
}: ITableRow): React.JSX.Element=> {
    const styleMain: React.CSSProperties = {
        background: backgroundColor,
        display: isVisible ? 'grid' : 'none'
    }

    const componentStyle: React.CSSProperties = {
        left: `calc(${indexContentWidth} - 14px)`,
        // background: backgroundColor,
        // border: '1px solid'
    }

    return (
        <div key={myKey} className={css.table_container__row} style={styleMain} onClick={onClick}>
            <span style={{ textAlign: indexContentTextAlign, width:  indexContentWidth, padding: indexContentPadding}}>{indexContent}</span>

            <div className={`${css.table_container__row_inset} ${classGridTemplateColumns}`} style={{background: backgroundColorInset, padding: paddingInset}}>
                {badge && <div className={css.table_container__row_inset_badge} style={componentStyle}>{badge}</div>}
                {children}
            </div>
        </div>
    )
})


export const TableRowCard = memo(({ myKey, isVisible = true, 
    children, childrenHeader, childrenFooter, backgroundColor, paddingHeader, paddingFooter,
    classGridTemplateColumnsForHeader, classGridTemplateColumnsForFooter, onClick
}: ITableRowCard): React.JSX.Element => {
    return <>
        {!isVisible ? ''
            : <div key={myKey} className={css.table_container__rowCard} style={{ background: backgroundColor }} onClick={onClick}>
                <div className={`${css.table_container__rowCard_header} ${classGridTemplateColumnsForHeader}`} style={{ padding: paddingHeader }}>{childrenHeader}</div>
                <div className={css.table_container__rowCard_content}>{children}</div>
                {childrenFooter && <div className={`${css.table_container__rowCard_footer} ${classGridTemplateColumnsForFooter}`} style={{ padding: paddingFooter }}>{childrenFooter}</div>}
            </div>
        }
    </>
})