import { ICompaniesUI } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconAdd, IconClearClose, IconCompany, IconInfo, IconSearch, IconUpdate } from "images/icons"
import { Button, Divider, Empty, Flex, Grid, Input, Loader, Pagination, Table, ToolTip } from "components/ui"


/** Контрагенты - Интерфейс */
export const CompaniesUI = ({
    txtSearch, isLoadingCompanies, companiesStatistics,
    companiesItems, totalCount, pageSize, currentPageNumber,
    loadDataList, txtSearchOnChange, handlerOpenCardCompany
}: ICompaniesUI) => {
    return <Flex gap="3px" className="page_container">
        <Loader isOpen={isLoadingCompanies} backdrop color="#f34e2e" title="Загрузка данных..." />

        <Flex gap="10px" direction="row" flexWrap='wrap' justifyContent="space-between" alignItems="center" className="page_container-header">
            <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title">
                <IconCompany width={28} height={28} />
                <h4>Контрагенты</h4>
            </Grid>

            <Flex gap="6px" direction="row" className="page_header_for_mobile">
                <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                    value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                    paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                    suffix={<>
                        {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                        <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                    </>}
                />

                <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataList(txtSearch.value)}><IconUpdate /></Button>
                <Button id='tooltipBtnAdd' size="sm" variant="icon" onClick={() => handlerOpenCardCompany(true, 'new')}><IconAdd /></Button>
            </Flex>
        </Flex>

        <Divider variant="dark" />

        <Table.Container>
            <Table.Header id='hiddenForMobile' classGridTemplateColumns="companies_grid_columns" marginLeft="54px" marginRight="20px">
                <span>Наименование контрагента<br />ИНН / КПП</span>
                <span>Телефон<br />Электронная почта</span>
            </Table.Header>

            <Table.Body>
                {companiesItems.length === 0
                    ? <Empty image={imgEmty} title='Данные отсутствуют' />
                    : companiesItems.map((item, index) => (
                        <Table.Row key={item.companyID} indexContent={<span>{index + 1}</span>} indexContentWidth="44px" paddingInset="0 10px 0 10px"
                        // badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}                                classGridTemplateColumns="applications_grid_columns" 
                        classGridTemplateColumns="companies_grid_columns" 
                        onClick={() => handlerOpenCardCompany(true, "edit", item.companyID)}
                        >

                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                <p>{item.companyName}</p>
                                <p style={{ opacity: '.7' }}>{item.companyINN || <span style={{color: 'red', fontWeight: 'bold'}}>ИНН ?</span>} / {item.companyKPP || <span style={{color: 'red', fontWeight: 'bold'}}>----</span>}</p>
                            </div>

                            <div id='hiddenFor600' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                <p>{item.companyPhone || <span style={{color: 'red', fontWeight: 'bold'}}>телефон ?</span>}</p>
                                <p style={{ opacity: '.7' }}>{item.companyEmail || <span style={{color: 'red', fontWeight: 'bold'}}>email ?</span>}</p>
                            </div>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table.Container>

        <Flex direction="row" justifyContent="space-between" alignItems="center" className="page_container-footer">
            <Flex gap="8px" direction="row" className="page_container-footer-stat">
                <div>Кол.: <span>{totalCount}</span></div>
                <Divider type="vertical" />
                <div>ЮЛ: <span>{companiesStatistics.company}</span></div>
                <Divider type="vertical" />
                <div>ИП: <span>{companiesStatistics.other}</span></div>
            </Flex>

            <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Наименованию<br/>🗸 ИНН<br/>🗸 КПП<br/>🗸 Телефону<br/>🗸 Email</ToolTip>
        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnAdd" content='Создать' place="bottom-end" />
    </Flex>
}