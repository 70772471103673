import { ILoginInterface } from './_types'

import logo from 'images/logo.png'
import { IconEye, IconEyeCrossed, IconPassword, IconTheme, IconUser } from 'images/icons'

import { Button, Flex, Input, ToolTip } from 'components/ui'


/** Аутентификация - Интерфейс */
export const LoginInterface = ({
    isLoading, txtLogin, txtPassword, isPassword,
    handlerButtonClickLogin, handlerFormKeyEnterLogin, toggleTheme
}: ILoginInterface) => {
    return <>
        <Flex direction='row' padding='4px' justifyContent='flex-end'>
            <Button size='sm' variant='icon' onClick={toggleTheme}><IconTheme /></Button>
        </Flex>

        <Flex height="100%" alignItems="center" justifyContent="center">
            <Flex gap="15px" className="auth_container">
                <Flex gap="5px" alignItems="center" textAlign="center">
                    <img src={logo} alt="Лого" />
                    <h4 style={{fontWeight: '500'}}>Мониторинг трудоустройства<br />ФГБОУ ВО Вавиловский университет</h4>
                </Flex>

                <form onKeyUp={handlerFormKeyEnterLogin} style={{ padding: '0 10px' }}>
                    <Flex gap="20px">
                        <Input size="lg" placeholder="Имя пользователя" prefix={<IconUser opacity={.6} />}
                            value={txtLogin.value} onChange={(e) => txtLogin.setValue(e.target.value)}
                        />

                        <Input size="lg" placeholder="Пароль пользователя" prefix={<IconPassword opacity={.6} />}
                            suffix={
                                <Button id="tooltipPassword" variant='icon' size='sm' onClick={() => isPassword.setValue(!isPassword.value)}>
                                    {isPassword.value ? <IconEyeCrossed /> : <IconEye />}
                                </Button>
                            }
                            type={isPassword.value ? 'text' : 'password'}
                            value={txtPassword.value} onChange={(e) => txtPassword.setValue(e.target.value)}
                        />

                        <Button size="lg" isLoading={isLoading} onClick={handlerButtonClickLogin}>ВОЙТИ</Button>
                    </Flex>
                </form>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipPassword" place="bottom-end" content={`${isPassword.value ? 'Скрыть пароль' : 'Показать пароль'}`} />
    </>
}