import { ISelectionDictionaryUI } from './_types'

import imgEmpty from 'images/empty.svg'
import { IconClearClose, IconSearch } from 'images/icons'

import { Empty, Flex, ToolTip, Modal, Loader, Input, Button, Table } from 'components/ui'


/** Выбор из справочника - Интерфейс */
export const SelectionDictionaryUI = ({isOpen, isLoading, headerText, txtSearch, selectedID, commonList, handlerButtonOk, handlerButtonCancel}: ISelectionDictionaryUI) => {
    return <Modal isOpen={isOpen} isEsc isWrapper onCancel={handlerButtonCancel}>
        <Flex className='modal_container'>
            <Flex className='modal_container-header' direction='row' alignItems='center' justifyContent='space-between'>
                <h4>{headerText || 'Выбор справочника...'}</h4>
                <Button id='tooltipClose' size='sm' variant='icon' onClick={handlerButtonCancel}><IconClearClose /></Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Flex gap='6px' className='modal_container-content' height='300px'>
                    {isLoading && <Loader isOpen={isLoading} backdrop color='#f34e2e' />}

                    <Input size='sm' paddingLeft={4} paddingRight={3} {...txtSearch} 
                        isDisabled={commonList.value.length === 0}
                        placeholder='Поиск по ключевым словам'
                        prefix={<IconSearch width={18} height={18} />}
                        suffix={txtSearch.value && <Button id='toolTipClear' size='xs' variant='icon' onClick={() => txtSearch.setValue('')}><IconClearClose width={18} height={18} /></Button>}
                    />

                    <Table.Container className='dict_container'>
                        <Table.Body >
                            {commonList.value.length === 0
                                ? <Empty image={imgEmpty} />
                                : commonList.value.filter((val) => {
                                    const { name } = val
                                    const searchValue = txtSearch.value ? txtSearch.value.toString().toLowerCase() : ''
            
                                    return (
                                        name.toString().toLowerCase().includes(searchValue)
                                    ) ? val : false
                                }).map((item, index) => (
                                    <Table.Row key={index + 1} indexContent={<span>{selectedID.value === item.id ? '✔' : index + 1}</span>} 
                                        paddingInset='4px 8px' indexContentWidth="40px" 
                                        classGridTemplateColumns="files_grid_columns" 
                                        onClick={() => {selectedID.setValue(item.id); handlerButtonOk && handlerButtonOk(item.id)}}
                                        backgroundColor={selectedID.value === item.id ? '#F34E2E' : ''}
                                    >
                                        <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                            <p>{item.name}</p>
                                            {/* {item.param1 ? <div>{item.param1} / {item.param2}</div> : ''} */}
                                        </div>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table.Container>
                </Flex>

                {/* <Grid gap='6px' gridTemplate='columns' gridTemplateColumns='1fr 1fr'>
                    <Button variant='primaryDark' width='100%' onClick={handlerButtonCancel}>ОТМЕНА</Button>
                    <Button width='100%' onClick={handlerButtonOk}>ВЫБРАТЬ</Button>
                </Grid> */}
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#toolTipClear" place="left" content='Очистить' />
        <ToolTip anchorSelect="#tooltipClose" place="left" content='Закрыть' />
    </Modal>
}