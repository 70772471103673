import axios from "axios"
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { resetStore } from '../reset.store'
import tokenService from 'services/token.service'

import { ILoginState, ILoginActions, IUserProfile, IResponseLogin, IResponseUserProfile } from './_types'

import api, { APP_API_URL, handleRequest } from 'services/api.service'
import { IResponseCommon } from 'store/types.common'


const route = '/auth'
axios.defaults.withCredentials = true


/** Начальное состояние  */
export const initialStateLogin: ILoginState = {
    error: null,
    isLoading: false,
    isAuthorized: false,

    profile: {} as IUserProfile,
}

/** Хранилище страницы - Авторизация */
export const useLogin = create<ILoginState & ILoginActions>()(devtools((set) => ({
    ...initialStateLogin,

    /** Авторизация в системе */
    loginUser: async (login: string, password: string) => {
        set({isLoading: true})

        const res = await handleRequest(api.post<IResponseLogin>(`${route}/login`, { login, password }))
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data?.token && res.data.tokenExpiration) {
            localStorage.setItem('us_id', res.data?.token)
            localStorage.setItem('us_exp', res.data.tokenExpiration.toString())

            tokenService.refreshToken(res.data.tokenExpiration)

            set({error: null})
            set({isAuthorized: true})
        }

        set({isLoading: false})
    },

    /** Проверка авторизации пользователя */
    checkUserAuth: async () => {
        set({isLoading: true})

        const res = await handleRequest(axios.get<IResponseLogin>(`${APP_API_URL}${route}/refresh`))
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data?.token && res.data.tokenExpiration) {
            localStorage.setItem('us_id', res.data?.token)
            localStorage.setItem('us_exp', res.data.tokenExpiration.toString())

            tokenService.refreshToken(res.data.tokenExpiration)

            set({error: null})
            set({isLoading: false})
            set({isAuthorized: true})

            return true
        } else {
            tokenService.abortRefreshToken()
            localStorage.removeItem('us_id')
            localStorage.removeItem('us_exp')
            resetStore()
        }

        set({isLoading: false})
        return false
    },

    /** Выход из системы */
    logoutUser: async () => {
        tokenService.abortRefreshToken()
        localStorage.removeItem('us_id')
        localStorage.removeItem('us_exp')
        resetStore()

        await handleRequest(api.post<IResponseCommon>(`${route}/logout`))
    },

    /** Получение данных по профилю текущего пользователя */
    getUserProfile: async () => {
        set({isLoading: true})

        const res = await handleRequest(api.get<IResponseUserProfile>(`${route}/getUserProfile`))
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isAuthorized: true})
            set({profile: res.data!})
        }

        set({isLoading: false})
    }

}), {name: 'loginState'}))