export interface IValidFormData {
    
    /** Имя поля */
    fieldName: string
    /** Значение поля */
    value: string
    /** Обязательное для проверки */
    isRequired: boolean
    /** Сообщение которое необходимо вывести */
    errorMessage: string

    // Тип проверки
    validType: 
    | 'notEmpty' // Не пустое
    | 'phoneMask' // Номер телефона по маске
    | 'email' // Электронная почта
    | 'date' // Действительность даты
    | 'time' // Действительность времени
    | 'numberDecimal' // Целое или десятичное число через точку
    | 'number_1-9' // Целое число, первая цифра обязательно с 1 по 9
    | 'number_0-9' // Любые цифры
    | 'snils' // СНИЛС
    | 'inn' // ИНН
    | 'kpp' // КПП
}

export interface IValidFormDataReturnData {
    fieldName: string
    message: string
}

export const validFormData = (dataForValid: IValidFormData[]): IValidFormDataReturnData[] => {
    let returnData: IValidFormDataReturnData[] = []

    for (const item of dataForValid) {
        switch (item.validType) {
            case 'notEmpty': { // Не пустое
                if (item.isRequired && item.value.trim().length === 0) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'phoneMask': { // Номер телефона по маске
                const re = /^7\d{10}$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'email': { // Электронная почта
                const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'date': { // Действительность даты
                if ((item.isRequired && !isValidDate(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidDate(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'time': { // Действительность времени
                if ((item.isRequired && !isValidTime(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidTime(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'numberDecimal': { // Целое или десятичное число через точку
                const re = /^[1-9]\d*(\.\d+)?$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'number_1-9': { // Целое число, первая цифра обязательно с 1 по 9
                const re = /^[1-9][0-9]*$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'number_0-9': { // Любые цифры
                const re = /^[0-9]+$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'snils': { // СНИЛС
                if ((item.isRequired && !isValidSnils(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidSnils(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }

            case 'inn': { // ИНН
                if ((item.isRequired && !isValidINN(item.value)) || (!item.isRequired && item.value.length > 0 && !isValidINN(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                } break
            }
           
            case 'kpp': {
                const re = /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/
                if ((item.isRequired && !re.test(item.value)) || (!item.isRequired && item.value.length > 0 && !re.test(item.value))) {
                    returnData.push({fieldName: item.fieldName, message: item.errorMessage})
                }
                break
            } 
        }
    }

    return returnData
}


/** Проверяем дату на валидность и существование */
export const isValidDate = (dateString: string) => {
    // Парсим введенную строку в объект Date
    const parts = dateString.split('.')
    const day = parseInt(parts[0], 10)
    const month = parseInt(parts[1], 10) - 1 // Месяцы в JavaScript начинаются с 0
    const year = parseInt(parts[2], 10)
    const date = new Date(year, month, day)

    // Проверяем, корректная ли дата и не произошла ли ошибка при парсинге
    return !isNaN(date.getTime()) && date.getDate() === day && date.getMonth() === month && date.getFullYear() === year
}
/** Проверяем время на валидность и существование */
export const isValidTime = (timeValue: string) => {
    // Проверяем формат времени
    if (!/^(?:[0-9]{2}:){2}[0-9]{2}$/.test(timeValue)) {
        return false
    }

    // Разбиваем время на часы, минуты и секунды
    const [hours, minutes, seconds] = timeValue.split(":").map(Number)

    // Проверяем допустимые значения для часов (от 0 до 23), минут и секунд (от 0 до 59)
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
        return false
    }

    // Время прошло проверку
    return true
}

/** Проверка СНИЛС на валидность */
export const isValidSnils = (snils: string): boolean => {
    let result = false

    let varSnils = snils.match(/\d+/g) // Используем регулярное выражение для извлечения цифр
    snils = varSnils ? varSnils.join("") : "" // Объединяем извлеченные цифры в строку

    // if (typeof snils === 'number') {
    //     snils = snils.toString()
    // } else if (typeof snils !== 'string') {
    //     snils = ''
    // }

    if (!snils.length) {
        // error.code = 1
        // error.message = 'СНИЛС пуст'
    } else if (/[^0-9]/.test(snils)) {
        // error.code = 2
        // error.message = 'СНИЛС может состоять только из цифр'
    } else if (snils.length !== 11) {
        // error.code = 3
        // error.message = 'СНИЛС может состоять только из 11 цифр'
    } else {
        let sum = 0
        for (let i = 0; i < 9; i++) {
            sum += parseInt(snils[i]) * (9 - i)
        }

        let checkDigit = 0
        if (sum < 100) {
            checkDigit = sum
        } else if (sum > 101) {
            checkDigit = parseInt(String(sum % 101))
            if (checkDigit === 100) {
                checkDigit = 0
            }
        }

        if (checkDigit === parseInt(snils.slice(-2))) {
            result = true
        } else {
            // error.code = 4
            // error.message = 'Неправильное контрольное число'
        }
    }

    return result
}
/** Проверка ИНН на валидность */
export const isValidINN = (inn: string): boolean => {
    let result = false

    let varSnils = inn.match(/\d+/g) // Используем регулярное выражение для извлечения цифр
    inn = varSnils ? varSnils.join("") : "" // Объединяем извлеченные цифры в строку

    // if (typeof inn === 'number') {
    //     inn = inn.toString()
    // } else if (typeof inn !== 'string') {
    //     inn = ''
    // }

    if (!inn.length) {
        // error.code = 1
        // error.message = 'ИНН пуст'
        result = false
    } else if (/[^0-9]/.test(inn)) {
        // error.code = 2
        // error.message = 'ИНН может состоять только из цифр'
        result = false
    } else if (![10, 12].includes(inn.length)) {
        // error.code = 3
        // error.message = 'ИНН может состоять только из 10 или 12 цифр'
        result = false
    } else {
        const checkDigit = (inn: string, coefficients: number[]): number => {
            let n = 0
            for (let i in coefficients) {
                n += coefficients[i] * parseInt(inn[i])
            }
            return parseInt((n % 11 % 10).toString())
        }
        switch (inn.length) {
            case 10:
                const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
                if (n10 === parseInt(inn[9])) {
                    result = true
                }
                break
            case 12:
                const n11 = checkDigit(inn.slice(0, 10), [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
                const n12 = checkDigit(inn.slice(0, 11), [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
                if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
                    result = true
                }
                break
        }
        if (!result) {
            // error.code = 4
            // error.message = 'Неправильное контрольное число'
            result = false
        }
    }   

    return result
}