import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './styles/index.scss'

import IndexRoute from './routes/index.routes'
import NotificationProvider from 'components/ui/notification/notification.provider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <BrowserRouter>
        <NotificationProvider>
            <IndexRoute />
        </NotificationProvider>
    </BrowserRouter>
)