import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { 
    IDictionaryActions, IDictionaryState,
    IResponseYearsReleases, IYearsReleasesList,
    IResponseFaculty, IFacultyList,
    IResponseSpeciality, ISpecialityList,
    IResponseEducationForm, IEducationFormList,
    IResponseEducationLevel, IEducationLevelList,
    IResponseFinanceType, IFinanceTypeList,
    IResponseCitizenship, ICitizenshipList,
    IResponseDisabilityGroup, IDisabilityGroupList,
    IResponseEmploymentStatuses, IEmploymentStatusesList,
    IResponseReasonForUnemployment, IReasonForUnemploymentList,
    IResponseEmploymentDuration, IEmploymentDurationList,
} from './_types'

import api, { handleRequest } from 'services/api.service'


const route = '/dict'


/** Начальное состояние  */
export const initialStateDictionary: IDictionaryState = {
    error: null,
    isLoading: false,
}

/** Хранилище - Справочники */
export const useDictionary = create<IDictionaryState & IDictionaryActions>()(devtools((set) => ({
    ...initialStateDictionary,


    /** Получение списка - годы выпусков */
    getYearsReleases: async () => {
        let returnData: IYearsReleasesList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseYearsReleases>(`${route}/getYearsReleases`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - факультеты */
    getFaculty: async () => {
        let returnData: IFacultyList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseFaculty>(`${route}/getFaculty`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - коды специальностей */
    getSpeciality: async (faculty: string) => {
        let returnData: ISpecialityList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseSpeciality>(`${route}/getSpeciality?faculty=${faculty}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - форма обучения */
    getEducationForm: async (faculty: string) => {
        let returnData: IEducationFormList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseEducationForm>(`${route}/getEducationForm?faculty=${faculty}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - уровень образования */
    getEducationLevel: async (faculty: string) => {
        let returnData: IEducationLevelList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseEducationLevel>(`${route}/getEducationLevel?faculty=${faculty}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - тип финансирования */
    getFinanceType: async (faculty: string) => {
        let returnData: IFinanceTypeList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseFinanceType>(`${route}/getFinanceType?faculty=${faculty}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - гражданство */
    getCitizenship: async (faculty: string, mainDict: number = 0) => {
        let returnData: ICitizenshipList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseCitizenship>(`${route}/getCitizenship?faculty=${faculty}&mainDict=${mainDict}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - группа инвалидности */
    getDisabilityGroup: async () => {
        let returnData: IDisabilityGroupList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseDisabilityGroup>(`${route}/getDisabilityGroup`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - статус трудоустройства */
    getEmploymentStatuses: async () => {
        let returnData: IEmploymentStatusesList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseEmploymentStatuses>(`${route}/getEmploymentStatuses`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - причина нетрудоустройства */
    getReasonForUnemployment: async () => {
        let returnData: IReasonForUnemploymentList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseReasonForUnemployment>(`${route}/getReasonForUnemployment`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({ error: null }) }, 2000)
        set({ isLoading: false })

        return returnData
    },

    /** Получение списка - продолжительность трудоустройства */
    getEmploymentDuration: async () => {
        let returnData: IEmploymentDurationList[] = []
        set({ isLoading: true })

        const res = await handleRequest(api.get<IResponseEmploymentDuration>(`${route}/getEmploymentDuration`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            returnData = res.data?.data || []
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set({ isLoading: false })

        return returnData
    },

}), { name: 'dictionaryState' }))