import React, { createContext, Dispatch, memo, Reducer, useReducer } from 'react'
import {nanoid} from 'nanoid'


import css from './notification.module.scss'
import Notification from './notification'
import { NotificationData, NotifyAction, NotifyType } from './notification.type'

/** Создаем контекст для уведомления */
export const NotificationContext = createContext<Dispatch<NotifyAction>>(Notification)

/** Открывает уведомление.
* @param {string} message - Сообщение уведомления
* @param {NotifyType} type - Тип уведомления
* @param {number} duration - Продолжительность отображения уведомления (в миллисекундах)
* @param {Dispatch<NotifyAction>} dispatch - Функция для отправки действия уведомления
*/
export const notifyOpen = ( message: string, type: NotifyType, duration: number, dispatch: Dispatch<NotifyAction>) => {
    dispatch({
        type: type,
        payload: {
            id: nanoid(),
            type: type,
            duration: duration,
            message: message,
        },
    })
}

/** Редуктор уведомлений */
const notifyReducer: Reducer<NotificationData[], NotifyAction> = (state, action) => {
    switch (action.type) {
        case 'info':
        case 'success':
        case 'warning':
        case 'error':
            return [...state, { ...action.payload! }]
        case 'REMOVE_NOTIFICATION':
            if (action.id) {
                return state.filter((el) => el.id !== action.id)
            }
            return state
    }
}

/** Компонент-провайдер для уведомлений.
* @param {React.PropsWithChildren<{}>} props - Свойства компонента-провайдера.
* @returns {JSX.Element} JSX элемент компонента-провайдера.
*/
export default memo((props: React.PropsWithChildren<{}>): JSX.Element => {
    const [state, dispatch] = useReducer(notifyReducer, [])

    const memoizedNotifications = React.useMemo(
        () =>
            state.map((note) => (
                <Notification dispatch={dispatch} key={note.id} {...note} />
            )),
        [state]
    )

    return (
        <NotificationContext.Provider value={dispatch}>
            <div className={css.notificationWrapper}>{memoizedNotifications}</div>
            {props.children}
        </NotificationContext.Provider>
    )
})