import { IPersonsUI } from './_types'

import imgEmty from 'images/empty.svg'
import { Button, Divider, Empty, Flex, Grid, Input, Loader, Pagination, Table, ToolTip } from 'components/ui'
import { IconClearClose, IconFilter, IconInfo, IconReport, IconSearch, IconStudents, IconUpdate } from 'images/icons'


/** Физические лица - Интерфейс */
export const PersonsUI = ({
    personsStatistics, personsItems, totalCount, pageSize, currentPageNumber, isLoadingPersons,
    filterIsOpen, filterIsActivate, currentYear, txtSearch, 
    txtSearchOnChange, loadDataList, handlerApplyClearFilter, handlerOpenReport, handlerOpenDictionary, handlerOpenCardPerson,
}: IPersonsUI) => {
    return <Flex gap="3px" className="page_container">
        <Loader isOpen={isLoadingPersons} backdrop color="#f34e2e" title="Загрузка данных..." />

        <Flex gap="10px" direction="row" flexWrap='wrap' justifyContent="space-between" alignItems="center" className="page_container-header">
            <Grid alignItems="center" gap="6px" gridTemplate="columns" gridTemplateColumns="auto 1fr" className="page_container-header-title year_change" onClick={() => handlerOpenDictionary(true, 'yearsReleases')}>
                <IconStudents width={28} height={28} />
                <h4 className='year_change'>Выпускники - {currentYear.value} год</h4>
            </Grid>

            <Flex gap="6px" direction="row" className="page_header_for_mobile">
                <Input size="sm" placeholder="Поиск..." paddingLeft={6} width='100%'
                    value={txtSearch.value} onChange={(e) => txtSearchOnChange(e.target.value)}
                    paddingRight={4} prefix={<IconSearch width={16} height={16} />}
                    suffix={<>
                        {txtSearch.value && <Button id='tooltipBtnClear' size="xs" variant="icon" onClick={() => txtSearchOnChange('')}><IconClearClose width={18} height={18} /></Button>}
                        <Button id='tooltipBtnSearchInfo' size="xs" variant="icon"><IconInfo width={18} height={18} /></Button>
                    </>}
                />

                <div style={{ position: 'relative' }}>
                    <Button id='tooltipBtnFilter' size="sm" variant="icon" onClick={() => filterIsOpen.setValue(true)}><IconFilter /></Button>
                    <div style={{ position: 'absolute', bottom: '-4px', right: '-4px' }}>
                        {filterIsActivate.value && <Button id='tooltipBtnClear' size="xs" variant="primary" onClick={() => handlerApplyClearFilter('clear')}>
                            <IconClearClose width={16} height={16} />
                        </Button>}
                    </div>
                </div>

                <Divider type="vertical" margin="3px" />

                <Button id='tooltipBtnUpdate' size="sm" variant="icon" onClick={() => loadDataList(txtSearch.value)}><IconUpdate /></Button>
                <Button id='tooltipBtnReport' size="sm" variant="icon" onClick={() => handlerOpenReport(true)}><IconReport /></Button>
            </Flex>
        </Flex>

        <Divider variant="dark" />

        <Table.Container>
            <Table.Header id='hiddenForMobile' classGridTemplateColumns="persons_grid_columns" marginLeft="54px" marginRight="20px">
                <span>Факультет 👉 ФИО выпускника<br />Гражданство / СНИЛС / ИНН</span>
                <span id='hiddenFor1100'>Код и наименование специальности<br />Наименование специализации</span>
                <span>Телефон<br />Электронная почта</span>
            </Table.Header>

            <Table.Body>
                {personsItems.length === 0
                    ? <Empty image={imgEmty} title='Данные отсутствуют' />
                    : personsItems.map((item, index) => (
                        <Table.Row key={item.personSourceGuid} indexContent={<span>{index + 1}</span>} indexContentWidth="44px" paddingInset="0 10px 0 10px"
                        // badge={(item.contractCount > 0 || item.applicationStatusID === 3) && <p className="table_badge" style={{ background: item.contractCount === 0 ? 'red' : item.applicationStatusColor }}>{item.contractCount}</p>}                                classGridTemplateColumns="applications_grid_columns" 
                        classGridTemplateColumns="persons_grid_columns" 
                        onClick={() => handlerOpenCardPerson(true, item.personSourceGuid)}
                        >

                            <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                <p>{`${item.faculty} 👉 ${item.lastName} ${item.firstName} ${item.secondName || ''}`.trim()}</p>
                                <p style={{ opacity: '.7' }}>{item.citizenshipName} / {item.snils || <span style={{color: 'red', fontWeight: 'bold'}}>СНИЛС ?</span>} / {item.inn || <span style={{color: 'red', fontWeight: 'bold'}}>ИНН ?</span>}</p>
                            </div>

                            <div id='hiddenFor1100' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                <p>{`${item.specialityCode} - ${item.specialityName}`.trim()}</p>
                                <p style={{ opacity: '.7' }}>{item.specializationName}</p>
                            </div>

                            <div id='hiddenFor600' style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                <p>{item.phone || <span style={{color: 'red', fontWeight: 'bold'}}>телефон ?</span>}</p>
                                <p style={{ opacity: '.7' }}>{item.email || <span style={{color: 'red', fontWeight: 'bold'}}>email ?</span>}</p>
                            </div>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table.Container>

        <Flex direction="row" justifyContent="space-between" alignItems="center" className="page_container-footer">
            <Flex gap="8px" direction="row" className="page_container-footer-stat">
                <div>Кол.: <span>{totalCount}</span></div>
                <Divider id='hiddenFor420' type="vertical" />
                <div id='hiddenFor420'>РФ: <span>{personsStatistics.rf}</span></div>
                <Divider type="vertical" />
                <div>Ино.: <span>{personsStatistics.ino}</span></div>
            </Flex>

            <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={currentPageNumber.value} onChange={(e) => currentPageNumber.setValue(e)} />
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnSearchInfo" place="bottom-end">Поиск по:<br/>🗸 Фамилии<br/>🗸 Имени<br/>🗸 Отчеству<br/>🗸 Телефону<br/>🗸 Email<br/>🗸 СНИЛС<br/>🗸 ИНН<br/>🗸 Коду специальности<br/>🗸 Наименованию специальности<br/>🗸 Наименованию специализации</ToolTip>
        <ToolTip anchorSelect="#tooltipBtnFilter" content='Фильтр' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnUpdate" content='Обновить список' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnReport" content='Отчёты' place="bottom-end" />
        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="bottom-end" />
    </Flex>
}