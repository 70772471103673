import { useContext, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { FilterListType, IPersonsFilterUI, IPersonsUI } from './_types'
import { PersonsUI } from './_interface'
import { PersonsFilterUI } from './_filter'

import { useInput } from 'hooks/useInput'
import { useDebounce } from 'hooks/useDebounce'

import { useLogin } from 'store/login'
import { IPersonFilter, usePersons } from 'store/persons'

import { CardPersonContainer, ICardPersonContainer } from 'components/smart/card_person'
import { CreatingReportContainer, ICreatingReportContainer } from 'components/smart/creating_report'
import { DictNameType, ISelectionDictionaryContainer, SelectionDictionaryContainer } from 'components/smart/selection_dictionary'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Физические лица - Контейнер */
export const PersonsContainer = () => {
    const notify = useContext(NotificationContext)

    const filterIsOpen = useInput<boolean>(false)
    const filterIsActivate = useInput<boolean>(false)
    const filterFacultyList = useInput<FilterListType[]>([])
    const filterSpecialityList = useInput<FilterListType[]>([])
    
    const filterEducationFormList = useInput<FilterListType[]>([])
    const filterEducationLevelList = useInput<FilterListType[]>([])
    const filterFinanceTypeList = useInput<FilterListType[]>([])
    const filterCitizenshipList = useInput<FilterListType[]>([])
    const filterIsTargetDirection = useInput<boolean>(false)
    const filterIsDisabilityGroup = useInput<boolean>(false)
    const filterIsDead = useInput<boolean>(false)
    
    const txtSearch = useInput<string>('')
    const currentYear = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    const modalCardPerson = useInput<ICardPersonContainer>({ isOpen: false })
    const modalReports = useInput<ICreatingReportContainer>({ isOpen: false })
    const modalDictionary = useInput<ISelectionDictionaryContainer>({ isOpen: false })

    
    /** Хранилище - Авторизация */
    const { userProfile } = useLogin(useShallow((state) => ({ userProfile: state.profile })))
    /** Хранилище - Физические лица */
    const { 
        errors, personsStatistics, isLoadingPersons, 
        isReloadPersons, personsItems, totalCount, pageSize, 
        editYearReleases, getPersons, clearPersonsState 
    } = usePersons(useShallow((state) => ({
        errors: state.error,
        personsStatistics: state.personsStatistics,

        isLoadingPersons: state.persons.isLoading,
        isReloadPersons: state.persons.isReload,
        editYearReleases: state.editYearReleases,
        
        getPersons: state.getPersons,
        personsItems: state.persons.items,
        totalCount: state.persons.totalCount,
        pageSize: state.persons.pageSize,

        clearPersonsState: state.clearPersonsState
    })))


    useEffect(() => {
        currentYear.setValue(userProfile.defaultYear)

        return (() => {
            clearPersonsState()
            handlerApplyClearFilter('clear')

            txtSearch.setValue('')
            currentYear.setValue('')
            currentPageNumber.setValue(1)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { // Загрузка данных
        loadDataList(txtSearch.value)
    }, [currentPageNumber.value, isReloadPersons]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { // Вывод ошибок
        if (errors?.message) return notifyOpen(errors?.message, errors?.type, 2000, notify)
    }, [errors?.message]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Загрузка основных данных */
    const loadDataList = (search?: string) => {
        let facultyListSelected: string = ''
        let specialityListSelected: string = ''
        
        let filterEducationFormListSelected: string = ''
        let filterEducationLevelListSelected: string = ''
        let filterFinanceTypeListSelected: string = ''
        let filterCitizenshipListSelected: string = ''

        if (filterFacultyList.value.length > 0) filterFacultyList.value.forEach((item) => { facultyListSelected += `'${item.name}',` })
        if (filterSpecialityList.value.length > 0) filterSpecialityList.value.forEach((item) => { specialityListSelected += `'${item.name}',` })
        if (filterEducationFormList.value.length > 0) filterEducationFormList.value.forEach((item) => { filterEducationFormListSelected += `'${item.name}',` })
        if (filterEducationLevelList.value.length > 0) filterEducationLevelList.value.forEach((item) => { filterEducationLevelListSelected += `'${item.name}',` })
        if (filterFinanceTypeList.value.length > 0) filterFinanceTypeList.value.forEach((item) => { filterFinanceTypeListSelected += `'${item.name}',` })
        if (filterCitizenshipList.value.length > 0) filterCitizenshipList.value.forEach((item) => { filterCitizenshipListSelected += `'${item.name}',` })

        const filterData: IPersonFilter = {
            faculty: facultyListSelected, 
            speciality: specialityListSelected, 
            educationForm: filterEducationFormListSelected, 
            educationLevel: filterEducationLevelListSelected, 
            financeType: filterFinanceTypeListSelected, 
            citizenship: filterCitizenshipListSelected,
            isTargetDirection: filterIsTargetDirection.value ? 1 : 0, 
            isDisabilityGroup: filterIsDisabilityGroup.value ? 1 : 0, 
            isDead: filterIsDead.value ? 1 : 0
        }

        getPersons(currentPageNumber.value, search || '', filterData)
    }


    /** Кнопка применить (очистить) в окне - Фильтр по заявкам */
    const handlerApplyClearFilter = (filterType: 'apply' | 'clear') => {
        switch (filterType) {
            case 'apply': {
                filterIsOpen.setValue(false)
                filterIsActivate.setValue(true)
                loadDataList(txtSearch.value)
                break
            }

            case 'clear': {
                filterIsOpen.setValue(false)
                filterIsActivate.setValue(false)
                filterFacultyList.setValue([])
                filterSpecialityList.setValue([])
                filterEducationFormList.setValue([])
                filterEducationLevelList.setValue([])
                filterFinanceTypeList.setValue([])
                filterCitizenshipList.setValue([])
                filterIsTargetDirection.setValue(false)
                filterIsDisabilityGroup.setValue(false)
                filterIsDead.setValue(false)

                const filterData: IPersonFilter = {
                    faculty: '', speciality: '', educationForm: '', 
                    educationLevel: '', financeType: '', citizenship: '',
                    isTargetDirection: 0, isDisabilityGroup: 0, isDead: 0
                }
        
                getPersons(currentPageNumber.value, txtSearch.value, filterData)
                break
            }
        }
    }
    /** Удаление элемента из списка фильтра по имени фильтра */
    const handlerDeleteItemToFilterList = (filterName: 'faculty' | 'speciality' | 'educationForm' | 'educationLevel' | 'financeType' | 'citizenship' , itemName: string) => {
        switch (filterName) {
            case 'faculty': { // Факультеты
                filterFacultyList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }

            case 'speciality': { // Коды специальностей
                filterSpecialityList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }

            case 'educationForm': { // Форма обучения
                filterEducationFormList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }

            case 'educationLevel': { // Уровень образования
                filterEducationLevelList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }

            case 'financeType': { // тип финансирования
                filterFinanceTypeList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }

            case 'citizenship': { // Гражданство
                filterCitizenshipList.setValue(prevList => 
                    prevList.filter(item => item.name !== itemName)
                )
                break
            }
        }
    }


    /** Открытие/закрытие окна анкеты физического лица */
    const handlerOpenCardPerson = (isOpen: boolean, cardID?: string) => {
        modalCardPerson.setValue({
            isOpen, cardID,
            handlerButtonCancel() { modalCardPerson.setValue({ isOpen: false }) },
            handlerButtonOk() {
                modalCardPerson.setValue({ isOpen: false })
                loadDataList(txtSearch.value)
            }
        })
    }


    /** Открытие/закрытие окна выбора справочника */
    const handlerOpenDictionary = (isOpen: boolean, dictName: DictNameType, whereParam1?: string, whereParam2?: string) => {
        modalDictionary.setValue({
            isOpen, dictName, whereParam1, whereParam2,
            handlerButtonCancel() { modalDictionary.setValue({ isOpen: false }) },
            returnSelectedData(id, name, param1, param2, param3, param4, param5, param6) {
                switch (dictName) {
                    case 'yearsReleases': { // Год выпуска
                        handlerApplyClearFilter('clear')
                        currentYear.setValue(name)
                        editYearReleases(name)
                        break
                    }

                    case 'faculty': { // Факультеты
                        let newList = [...filterFacultyList.value];
    
                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterFacultyList.setValue(newList)
                        }
                        break
                    }

                    case 'speciality': { // Коды специальностей
                        let newList = [...filterSpecialityList.value]

                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterSpecialityList.setValue(newList)
                        }
                        break
                    }

                    case 'educationForm': { // Форма обучения
                        let newList = [...filterEducationFormList.value]

                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterEducationFormList.setValue(newList)
                        }
                        break
                    }

                    case 'educationLevel': { // Уровень образования
                        let newList = [...filterEducationLevelList.value]

                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterEducationLevelList.setValue(newList)
                        }
                        break
                    }

                    case 'financeType': { // Тип финансирования
                        let newList = [...filterFinanceTypeList.value]

                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterFinanceTypeList.setValue(newList)
                        }
                        break
                    }

                    case 'citizenship': { // Гражданство
                        let newList = [...filterCitizenshipList.value]

                        // Проверяем, существует ли уже элемент с таким name
                        const exists = newList.some(item => item.name === name)
                        
                        // Если элемент не найден, добавляем его
                        if (!exists) {
                            newList.push({ name, isSelected: true })
                            filterCitizenshipList.setValue(newList)
                        }
                        break
                    }
                }

                modalDictionary.setValue({ isOpen: false })
            }
        })
    }
    /** Открытие/закрытие окна отчетов */
    const handlerOpenReport = (isOpen: boolean) => {
        modalReports.setValue({
            isOpen,
            handlerButtonCancel() { modalReports.setValue({ isOpen: false }) },
        })
    }


    /** Запуск загрузки основного списка через полторы секунды */
    const debounceSearch = useDebounce(loadDataList, 1500)
    /** Поле поиска - изменение и запуск загрузки */
    const txtSearchOnChange = (value: string) => {
        txtSearch.setValue(value)
        debounceSearch(value)
    }


    /** Свйоства передаваемые в компоненту */
    const propsToUI: IPersonsUI = {
        isLoadingPersons, personsStatistics,
        personsItems, totalCount, pageSize, currentYear, currentPageNumber,

        filterIsOpen, filterIsActivate,
        handlerApplyClearFilter,

        txtSearch, txtSearchOnChange, loadDataList,
        handlerOpenReport, handlerOpenDictionary, handlerOpenCardPerson,
    }

    /** Свойства передаваемые в фильтр */
    const propsToFilterUI: IPersonsFilterUI = {
        isOpen: filterIsOpen.value,
        accessLevelID: userProfile.accessLevelID,
        filterFacultyList, filterSpecialityList, filterEducationFormList,
        filterEducationLevelList, filterFinanceTypeList, filterCitizenshipList,
        filterIsTargetDirection, filterIsDisabilityGroup, filterIsDead,

        handlerOpenDictionary, handlerApplyClearFilter, handlerDeleteItemToFilterList,
        handlerButtonCancel: () => filterIsOpen.setValue(false),
    }


    return <>
        <PersonsUI {...propsToUI} />
        <PersonsFilterUI {...propsToFilterUI} />
        <CardPersonContainer {...modalCardPerson.value} />

        <CreatingReportContainer {...modalReports.value} />
        <SelectionDictionaryContainer {...modalDictionary.value} />
    </>
}