import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IPersonCard, IPersonCardEducation, IPersonCardEmployment, IPersonCardSave, IPersonEducationCard, IPersonEducationCardSave, IPersonEmploymentCard, IPersonEmploymentCardSave, IPersonFilter, IPersonsActions, IPersonsList, IPersonsState, IPersonStatistics, IResponsePersonCard, IResponsePersonCardEducations, IResponsePersonCardEmployments, IResponsePersonEducationCard, IResponsePersonEmploymentCard, IResponsePersons, IResponsePersonsStatistics, IResponseReportFiles } from './_types'

import api, { handleRequest } from 'services/api.service'
import { IResponseCommon } from 'store/types.common'


const route = '/persons'


/** Начальное состояние  */
export const initialStatePersons: IPersonsState = {
    error: null,
    personsStatistics: {} as IPersonStatistics,

    persons: {
        isLoading: false,
        isReload: false,
        items: [] as IPersonsList[],

        totalCount: 0,
        pageSize: 0,
        currentPage: 0
    },

    personCard: {
        isLoading: false,
        items: {} as IPersonCard
    },

    personCardEducations: {
        isLoading: false,
        isReload: false,
        items: [] as IPersonCardEducation[]
    },

    personCardEmployments: {
        isLoading: false,
        isReload: false,
        items: [] as IPersonCardEmployment[]
    },

    personEducationCard: {
        isLoading: false,
        items: {} as IPersonEducationCard
    },

    personEmploymentCard: {
        isLoading: false,
        items: {} as IPersonEmploymentCard
    },

}

/** Хранилище страницы - Физические лица */
export const usePersons = create<IPersonsState & IPersonsActions>()(devtools((set) => ({
    ...initialStatePersons,

    /** Установка хранилища до начального состояния */
    clearPersonsState: () => {set(initialStatePersons)},
    /** Очистка данных по анкете физического лица */
    clearPersonCard: () => { 
        set((state) => ({ 
            personCard: { ...state.personCard, items: {} as IPersonCard },
            personCardEducations: { ...state.personCardEducations, items: {} as IPersonCardEducation[] },
            personCardEmployments: { ...state.personCardEmployments, items: {} as IPersonCardEmployment[] }
        })
    )},
    /** Очистка данных по анкете образования физического лица */
    clearPersonEducationCard: () => { set((state) => ({ personEducationCard: { ...state.personEducationCard, items: {} as IPersonEducationCard } })) },
    /** Очистка данных по анкете трудоустройства физического лица */
    clearPersonEmploymentCard: () => { set((state) => ({ personEmploymentCard: { ...state.personEmploymentCard, items: {} as IPersonEmploymentCard } })) },


    /** Получение списка физических лиц */
    getPersons: async (pageNumber: number, search: string, filter?: IPersonFilter) => {
        set((state) => ({ persons: { ...state.persons, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersons>(`${route}/getPersons`, { params: { pageNumber, search, filter } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        const resStat = await handleRequest(api.get<IResponsePersonsStatistics>(`${route}/getPersonsStatistics`))
        if (resStat.status !== 200) set({ error: { type: resStat.status === 500 ? 'error' : 'warning', message: resStat.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ persons: { ...state.persons, isReload: false } }))

            set((state) => ({
                personsStatistics: {
                    ...state.personsStatistics,
                    ...resStat.data
                },
                persons: {
                    ...state.persons, 
                    items: res.data!.data,
                    totalCount: res.data!.totalCount,
                    pageSize: res.data!.pageSize,
                    currentPage: res.data!.currentPage
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({persons: {...state.persons, isLoading: false}}))

        return res
    },
    /** Изменение года выпуска по умолчанию */
    editYearReleases: async (year: string) => {
        const res = await handleRequest(api.put<IResponseCommon>(`${route}/editYearReleases?year=${year}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ persons: { ...state.persons, isReload: true } }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        return res
    },



    /** Создание отчётов */
    createReport: async (reportName: string, citizenshipNames: string) => {
        const res = await handleRequest(api.post<IResponseCommon>(`${route}/createReport?reportName=${reportName}&citizenshipNames=${citizenshipNames}`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            
        }

        setTimeout(() => { set({error: null}) }, 2000)
        return res
    },
    /** Получение списка файлов с отчётами */
    getReportFiles: async () => {
        const res = await handleRequest(api.get<IResponseReportFiles>(`${route}/getReportFiles`))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } })

        if (res.message === 'OK') {
            
        }

        setTimeout(() => { set({ error: null }) }, 2000)
        return res
    },
    


    /** Получение данных по анкете физического лица */
    getPersonCard: async (personID: string) => {
        set((state) => ({ personCard: { ...state.personCard, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersonCard>(`${route}/getPersonCard`, { params: { personID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({
                personCard: {
                    ...state.personCard, 
                    items: res.data || {} as IPersonCard
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personCard: { ...state.personCard, isLoading: false } }))

        return res
    },
    /** Получение списка выпусков по анкете */
    getPersonCardEducations: async (personID: string) => {
        set((state) => ({ personCardEducations: { ...state.personCardEducations, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersonCardEducations>(`${route}/getPersonCardEducations`, { params: { personID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ personCardEducations: { ...state.personCardEducations, isReload: false } }))

            set((state) => ({
                personCardEducations: {
                    ...state.personCardEducations, 
                    items: res.data!.data
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({personCardEducations: {...state.personCardEducations, isLoading: false}}))

        return res
    },
    /** Получение списка трудоустройств по анкете */
    getPersonCardEmployments: async (personID: string) => {
        set((state) => ({ personCardEmployments: { ...state.personCardEmployments, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersonCardEmployments>(`${route}/getPersonCardEmployments`, { params: { personID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ personCardEmployments: { ...state.personCardEmployments, isReload: false } }))

            set((state) => ({
                personCardEmployments: {
                    ...state.personCardEmployments, 
                    items: res.data!.data
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({personCardEmployments: {...state.personCardEmployments, isLoading: false}}))

        return res
    },

    /** Получение данных по анкете образования физического лица */
    getPersonEducationCard: async (studentID: string) => {
        set((state) => ({ personEducationCard: { ...state.personEducationCard, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersonEducationCard>(`${route}/getPersonEducationCard`, { params: { studentID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({
                personEducationCard: {
                    ...state.personEducationCard, 
                    items: res.data || {} as IPersonEducationCard
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personEducationCard: { ...state.personEducationCard, isLoading: false } }))

        return res
    },
    /** Получение данных по анкете трудоустройства физического лица */
    getPersonEmploymentCard: async (employmentID: string) => {
        set((state) => ({ personEmploymentCard: { ...state.personEmploymentCard, isLoading: true } }))

        const res = await handleRequest(api.get<IResponsePersonEmploymentCard>(`${route}/getPersonEmploymentCard`, { params: { employmentID } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({
                personEmploymentCard: {
                    ...state.personEmploymentCard, 
                    items: res.data || {} as IPersonEmploymentCard
                }
            }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personEmploymentCard: { ...state.personEmploymentCard, isLoading: false } }))

        return res
    },


    /** Сохранение данных по анкете физического */
    setPersonCardSave: async (personID: string, cardData: IPersonCardSave) => {
        set((state) => ({ personCard: { ...state.personCard, isLoading: true } }))

        const res = await handleRequest(api.put<IResponseCommon>(`${route}/setPersonCardSave?personID=${personID}`, { personData: JSON.stringify(cardData) }, { headers: { "Content-Type": `multipart/form-data` } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            // set((state) => ({ persons: { ...state.persons, isReload: true } }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personCard: { ...state.personCard, isLoading: false } }))

        return res
    },
    /** Сохранение данных по анкете образования */
    setPersonEducationCardSave: async (studentID: string, cardData: IPersonEducationCardSave) => {
        set((state) => ({ personEducationCard: { ...state.personEducationCard, isLoading: true } }))

        const res = await handleRequest(api.put<IResponseCommon>(`${route}/setPersonEducationCardSave?studentID=${studentID}`, { educationData: JSON.stringify(cardData) }, { headers: { "Content-Type": `multipart/form-data` } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ personCardEducations: { ...state.personCardEducations, isReload: true } }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personEducationCard: { ...state.personEducationCard, isLoading: false } }))

        return res
    },
    /** Сохранение данных по анкете трудоустройства */
    setPersonEmploymentCardSave: async (employmentID: string, cardData: IPersonEmploymentCardSave) => {
        set((state) => ({ personEmploymentCard: { ...state.personEmploymentCard, isLoading: true } }))

        const res = await handleRequest(api.put<IResponseCommon>(`${route}/setPersonEmploymentCardSave?employmentID=${employmentID}`, { employmentData: JSON.stringify(cardData) }, { headers: { "Content-Type": `multipart/form-data` } }))
        if (res.status !== 200) set({ error: { type: res.status === 500 ? 'error' : 'warning', message: res.message } }) 

        if (res.message === 'OK') {
            set((state) => ({ personCardEmployments: { ...state.personCardEmployments, isReload: true } }))
        }

        setTimeout(() => { set({error: null}) }, 2000)
        set((state) => ({ personEmploymentCard: { ...state.personEmploymentCard, isLoading: false } }))

        return res
    },

}), { name: 'personsState' }))