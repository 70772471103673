import { useContext, useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import { useInput } from "hooks/useInput"
import { useDebounce } from "hooks/useDebounce"

import { useCompanies } from "store/companies"

import { CardModeType } from "../common.types"
import { CardCompanyContainer, ICardCompanyContainer } from "../card_company"

import { SelectionCompanyUI } from "./_interface"
import { ISelectionCompanyContainer, ISelectionCompanyUI } from "./_types"

import { NotificationContext, notifyOpen } from "components/ui/notification/notification.provider"


/** Выбор контрагента - Контейнер */
export const SelectionCompanyContainer = ({ isOpen, isCompany, handlerButtonOk, handlerButtonCancel }: ISelectionCompanyContainer) => {
    const notify = useContext(NotificationContext)

    const txtSearch = useInput<string>('')
    const currentPageNumber = useInput<number>(1)

    const modalCardCompany = useInput<ICardCompanyContainer>({ isOpen: false })

    /** Хранилище - Контрагенты */
    const { 
        errors, companiesStatistics, isLoadingCompanies, 
        isReloadCompanies, companiesItems, totalCount, pageSize, 
        getCompanies, clearCompaniesState 
    } = useCompanies(useShallow((state) => ({
        errors: state.error,
        companiesStatistics: state.companiesStatistics,

        isLoadingCompanies: state.companies.isLoading,
        isReloadCompanies: state.companies.isReload,
        
        getCompanies: state.getCompanies,
        companiesItems: state.companies.items,
        totalCount: state.companies.totalCount,
        pageSize: state.companies.pageSize,

        clearCompaniesState: state.clearCompaniesState
    })))


    useEffect(() => {
        if (!isOpen) {
            clearCompaniesState()

            txtSearch.setValue('')
            currentPageNumber.setValue(1)
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { // Загрузка данных
        isOpen && loadDataList(txtSearch.value, isCompany)
    }, [isOpen, isCompany, currentPageNumber.value, isReloadCompanies]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => { // Вывод ошибок
        if (errors?.message) return notifyOpen(errors?.message, errors?.type, 2000, notify)
    }, [errors?.message]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Загрузка основных данных */
    const loadDataList = (search?: string, isCompanyType?: number) => {
        getCompanies(currentPageNumber.value, search || '', isCompanyType || isCompany)
    }

    /** Открытие/закрытие анкеты контрагента */
    const handlerOpenCardCompany = (isOpen: boolean, cardMode: CardModeType, cardID?: string) => {
        modalCardCompany.setValue({
            isOpen, cardMode, cardID,
            handlerButtonCancel() { modalCardCompany.setValue({ isOpen: false }) },
            handlerButtonOk() {
                modalCardCompany.setValue({ isOpen: false })
                loadDataList(txtSearch.value, isCompany)
            }
        })
    }

    /** Запуск загрузки основного списка через полторы секунды */
    const debounceSearch = useDebounce(loadDataList, 1500)
    /** Поле поиска - изменение и запуск загрузки */
    const txtSearchOnChange = (value: string) => {
        txtSearch.setValue(value)
        debounceSearch(value)
    }


    /** Свойства передаваемые в UI */
    const propsToUI: ISelectionCompanyUI = {
        isOpen, isLoading: isLoadingCompanies, companiesItems,
        txtSearch, currentPageNumber, companiesStatistics,
        totalCount, pageSize, 

        loadDataList,
        txtSearchOnChange, handlerOpenCardCompany,
        handlerButtonOk, handlerButtonCancel
    }
    
    return <>
        <SelectionCompanyUI {...propsToUI} />
        <CardCompanyContainer {...modalCardCompany.value} />
    </>
}