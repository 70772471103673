import { Navigate } from 'react-router-dom'
import { Route, Routes } from 'react-router'

import { LoginContainer } from 'pages/login'
import { MainContainer } from 'pages/main'


/** Маршруты для index.tsx */
const IndexRoute = () => {
    return (
        <Routes>
            <Route key='/' path='/' element={<LoginContainer />} />
            <Route key='/lk/*' path='/lk/*' element={<MainContainer />} />
            <Route key='*' path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default IndexRoute