import { ICardCompanyUI } from "./_types"

import { IconClearClose, IconCompany } from "images/icons"
import { Button, Divider, Flex, Grid, Input, Loader, Modal, Textarea, Toogle, ToolTip } from "components/ui"


/** Анкета контрагента - Интерфейс */
export const CardCompanyUI = ({
    isOpen, cardMode, fieldsError, isLoadingCard, loadTitle,
    txtCompanyINN, txtCompanyKPP, 
    txtCompanyName, txtCompanyPhone, 
    txtCompanyEmail, isVisibleKPP,
    txtCompanyINNOnChange, txtCompanyKPPOnChange,
    handlerBtnSave, handlerButtonCancel,
}: ICardCompanyUI) => {

    /** Кнопка очистки содержимого в поле */
    const getBtnClear = (handleBtnClick: () => void) => {
        return <Button id="tooltipBtnClear" size="sm" variant="icon" onClick={handleBtnClick}><IconClearClose width={18} height={18} /></Button>
    }


    return <Modal isOpen={isOpen} onCancel={handlerButtonCancel} overflowY="auto" maxWidth='400px' className="maxHeight500">
        <Flex className='modal_container'>
            <Flex direction="row" alignItems="center" justifyContent="space-between" className='modal_container-header'>
                <Flex gap='4px' direction='row' alignItems='center'>
                    <IconCompany color='#fff' width={20} height={20} />
                    <h4>{cardMode === 'edit' ? 'Анкета контрагента' : 'Создание контрагента'}</h4>
                </Flex>

                <Button variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='modal_container-body'>
                <Loader isOpen={isLoadingCard} backdrop={true} color="#f34e2e" title={loadTitle.value} />

                <Flex gap="15px" className='modal_container-content'>
                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyINN ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">ИНН контрагента <p>✸</p></Flex>
                            <Input placeholder="ИНН контрагента" paddingRight={4} maxLength={12}
                                suffix={txtCompanyINN.value && getBtnClear(() => txtCompanyINN.setValue(''))} 
                                onChange={(e) => txtCompanyINNOnChange(e.target.value)}
                                value={txtCompanyINN.value}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap" isVisible={isVisibleKPP.value}>
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyKPP ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">КПП контрагента <p>✸</p></Flex>
                            <Input placeholder="КПП контрагента" paddingRight={4} maxLength={9}
                                suffix={txtCompanyKPP.value && getBtnClear(() => txtCompanyKPP.setValue(''))}
                                onChange={(e) => txtCompanyKPPOnChange(e.target.value)}
                                value={txtCompanyKPP.value}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyName ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Наименование контрагента <p>✸</p></Flex>
                            <Textarea placeholder="Наименование контрагента" isReadOnly rows={6}
                                onChange={(e) => txtCompanyName.setValue(e.target.value)}
                                value={txtCompanyName.value}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyPhone ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Телефон контрагента <p>✸</p></Flex>
                            <Input placeholder="Телефон контрагента" paddingRight={4} maxLength={11} {...txtCompanyPhone} 
                                suffix={txtCompanyPhone.value && getBtnClear(() => txtCompanyPhone.setValue(''))}
                            />
                        </Flex>
                    </Flex>

                    <Flex gap="8px" direction="row" justifyContent="space-between" flexWrap="wrap">
                        <Flex flexGrow={1} flexBasis={250} className={!fieldsError.value.companyEmail ? 'input_error' : 'input_error input_error-active'}>
                            <Flex gap="5px" direction="row" className="input_header">Email контрагента <p>✸</p></Flex>
                            <Input placeholder="Email контрагента" paddingRight={4} suffix={txtCompanyEmail.value && getBtnClear(() => txtCompanyEmail.setValue(''))} {...txtCompanyEmail} />
                        </Flex>
                    </Flex>
                </Flex>

                <Divider />

                <Grid gap='6px' gridTemplate='columns' gridTemplateColumns='1fr 1fr'>
                    <Button variant='primaryDark' width='100%' onClick={handlerButtonCancel}>ОТМЕНА</Button>
                    <Button width='100%' onClick={handlerBtnSave}>
                        {cardMode === 'edit' ? 'СОХРАНИТЬ' : 'СОЗДАТЬ'}
                    </Button>
                </Grid>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClear" content='Очистить' place="left" />
    </Modal>
}