import { memo, useContext, useEffect } from 'react'

import { SelectionDictionaryUI } from './_interface'
import { CommonListType, ISelectionDictionaryContainer, ISelectionDictionaryUI } from './_types'

import { useInput } from 'hooks/useInput'
import { useDictionary } from 'store/dictionary'

import { NotificationContext, notifyOpen } from 'components/ui/notification/notification.provider'


/** Выбор из справочника - Контейнер */
export const SelectionDictionaryContainer = memo(({isOpen, dictName, whereParam1, whereParam2, handlerButtonCancel, returnSelectedData}: ISelectionDictionaryContainer) => {
    const notify = useContext(NotificationContext)

    const { 
        isLoading, error, 
        getYearsReleases, getFaculty, getSpeciality, 
        getEducationForm, getEducationLevel, getFinanceType, 
        getCitizenship, getDisabilityGroup, getEmploymentStatuses,
        getReasonForUnemployment, getEmploymentDuration
    } = useDictionary()
    
    const headerText = useInput<string>('')
    const txtSearch = useInput<string>('')

    const selectedID = useInput<string | number>('')
    const commonList = useInput<CommonListType[]>([])


    useEffect(() => {
        if (isOpen) {
            switch (dictName) {
                case 'yearsReleases': { // Годы выпусков
                    getYearsReleases().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.year,
                                name: item.year,
                            })
                        }))
                    })
                    headerText.setValue('Выбор года выпуска')
                    break
                }

                case 'faculty': { // Факультеты
                    getFaculty().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.faculty,
                                name: item.faculty,
                            })
                        }))
                    })
                    headerText.setValue('Выбор подразделения')
                    break
                }

                case 'speciality': { // Коды специальностей
                    getSpeciality(whereParam1 as string).then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.specialityCode,
                                name: item.specialityCode,
                            })
                        }))
                    })
                    headerText.setValue('Выбор специальности')
                    break
                }

                case 'educationForm': { // Форма обучения
                    getEducationForm(whereParam1 as string).then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.educationForm,
                                name: item.educationForm,
                            })
                        }))
                    })
                    headerText.setValue('Выбор формы обучения')
                    break
                }

                case 'educationLevel': { // Уровень образования
                    getEducationLevel(whereParam1 as string).then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.educationLevel,
                                name: item.educationLevel,
                            })
                        }))
                    })
                    headerText.setValue('Выбор уровня образования')
                    break
                }

                case 'financeType': { // Тип финансирования
                    getFinanceType(whereParam1 as string).then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.financeType,
                                name: item.financeType,
                            })
                        }))
                    })
                    headerText.setValue('Выбор типа финансирования')
                    break
                }

                case 'citizenship': { // Гражданство
                    getCitizenship(whereParam1 as string, Number(whereParam2)).then((res) => {
                        if (res) {
                            commonList.setValue(res.map((item) => {
                                return ({
                                    id: item.citizenshipID,
                                    name: item.citizenshipName,
                                })
                            }))
                        }
                    })
                    headerText.setValue('Выбор гражданства')
                    break
                }

                case 'disabilityGroup': { // Группа инвалидности
                    getDisabilityGroup().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.disabilityGroupID,
                                name: item.disabilityGroupName,
                            })
                        }))
                    })
                    headerText.setValue('Выбор группы инвалидности')
                    break
                }

                case 'employmentStatus': { // Статус трудоустройства
                    getEmploymentStatuses().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.employmentStatusID,
                                name: item.employmentStatusName,
                            })
                        }))
                    })
                    headerText.setValue('Статус трудоустройства')
                    break
                }

                case 'reasonForUnemployment': { // Причина нетрудоустройства
                    getReasonForUnemployment().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.reasonForUnemploymentID,
                                name: item.reasonForUnemploymentName,
                            })
                        }))
                    })
                    headerText.setValue('Причина нетрудоустройства')
                    break
                }

                case 'employmentDuration': { // Продолжительность трудоустройства
                    getEmploymentDuration().then((res) => {
                        commonList.setValue(res.map((item) => {
                            return ({
                                id: item.employmentDurationID,
                                name: item.employmentDurationName,
                            })
                        }))
                    })
                    headerText.setValue('Продолжительность занятости')
                    break
                }
            }
        } else {
            clearDict()
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error?.message) return notifyOpen(error?.message, error?.type, 2000, notify)
    }, [error?.message]) // eslint-disable-line react-hooks/exhaustive-deps


    /** Функция обнуления данных по справочнику */
    const clearDict = () => {
        // clearDictState()

        headerText.setValue('')
        txtSearch.setValue('')

        selectedID.setValue(0)
        commonList.setValue([])
    }

    /** Нажатие на кнопку выбрать */
    const handlerButtonOk = (selectedID: string | number) => {
        const selectedRowToList = commonList.value.filter(val => val.id === selectedID)
        if (selectedRowToList.length === 0) return notifyOpen('Для продолжения необходимо выбрать значение из справочника.', 'info', 2000, notify)

        returnSelectedData && returnSelectedData(
            selectedRowToList[0].id, selectedRowToList[0].name, 
            selectedRowToList[0].param1, selectedRowToList[0].param2, 
            selectedRowToList[0].param3, selectedRowToList[0].param4, 
            selectedRowToList[0].param5, selectedRowToList[0].param6
        )
    }

    /** Свойства передаваемые в компоненту */
    const propsToComponent: ISelectionDictionaryUI = {
        isOpen, isLoading,
        headerText: headerText.value, txtSearch, 
        selectedID, commonList,
        handlerButtonOk, handlerButtonCancel,
    }

    return <SelectionDictionaryUI {...propsToComponent} />
})