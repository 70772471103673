import { ICreatingReportUI } from "./_types"

import imgEmty from 'images/empty.svg'
import { IconClearClose, IconReport } from "images/icons"

import { Button, Divider, Drawer, Empty, Flex, Loader, Table, ToolTip } from "components/ui"


/** Создание отчёта - Интерфейс */
export const CreatingReportUI = ({ 
    isOpen, isLoadReport, loadReportTitle, selectedReport, reportFilesList,
    handlerButtonCancel, handlerButtonCreateReport
}: ICreatingReportUI) => {
    return <Drawer isOpen={isOpen} maxWidth='370px' isWrapper isEsc onCancel={handlerButtonCancel}>
        <Flex className='drawer_container'>
            <Flex direction='row' alignItems='center' justifyContent='space-between' className='drawer_container-header'>
                <header>
                    <Flex gap='4px' direction='row' alignItems='center'>
                        <IconReport color='#fff' width={20} height={20} />
                        <h4>Создание отчётов</h4>
                    </Flex>
                </header>

                <Button id='tooltipBtnClose' variant='icon' size='xs' onClick={handlerButtonCancel}>
                    <IconClearClose />
                </Button>
            </Flex>

            <Flex gap='4px' className='drawer_container-body'>
                <Flex gap='6px' className='drawer_container-content' overflow="hidden" padding="0px">
                    <Loader isOpen={isLoadReport.value} color='#f34e2e' title={loadReportTitle.value} backdrop />

                    <Flex gap="2px">
                        <Divider prefix='Выберите отчёт' />

                        <Flex gap="4px" flexWrap="wrap" className="section">
                            <Table.Row indexContentWidth="25px" paddingInset="2px 6px" 
                                backgroundColor={selectedReport.value === 'REP_01' ? '#f34e2e' : '#373D58'}
                                indexContent={selectedReport.value === 'REP_01' ? '✔' : '✘'}
                                onClick={() => selectedReport.setValue('REP_01')}
                            >AP8</Table.Row>

                            <Table.Row indexContentWidth="25px" paddingInset="2px 6px" 
                                backgroundColor={selectedReport.value === 'REP_02' ? '#f34e2e' : '#373D58'}
                                indexContent={selectedReport.value === 'REP_02' ? '✔' : '✘'}
                                onClick={() => selectedReport.setValue('REP_02')}
                            >Данные по трудоустройству</Table.Row>

                            <Table.Row indexContentWidth="25px" paddingInset="2px 6px" 
                                backgroundColor={selectedReport.value === 'REP_03' ? '#f34e2e' : '#373D58'}
                                indexContent={selectedReport.value === 'REP_03' ? '✔' : '✘'}
                                onClick={() => selectedReport.setValue('REP_03')}
                            >Данные по выпускникам</Table.Row>
                        </Flex>
                    </Flex>

                    <Divider variant="dark" />

                    <Table.Body>
                        {reportFilesList.value.length === 0 
                            ? <Empty image={imgEmty} title='Отчёты отсутствуют' />
                            : reportFilesList.value.map((item) => {
                                const splittingString = item.fileName.split('__')

                                return (
                                    <a key={item.reportFileID} href={item.filePath} target="_blank" rel="noreferrer">
                                    <Table.Row indexContent={item.reportFileID} indexContentWidth="40px">
                                        <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
                                            <p>{splittingString[0]}</p>
                                            <p style={{ opacity: '.7' }}>{splittingString[1]} {splittingString[2].replace('.xlsx', '')}</p>
                                        </div>
                                    </Table.Row>
                                    </a>
                                )
                            })
                        }
                    </Table.Body>
                </Flex>

                <Button onClick={handlerButtonCreateReport}>Создать отчёт</Button>
            </Flex>
        </Flex>

        <ToolTip anchorSelect="#tooltipBtnClose" content='Закрыть' place="bottom-start" />
    </Drawer>
}