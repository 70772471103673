import { initialStateLogin, useLogin } from "./login"
import { initialStatePersons, usePersons } from "./persons"
import { initialStateDictionary, useDictionary } from "./dictionary"
import { initialStateCompanies, useCompanies } from "./companies"



/** Сброс всего хранилища */
export const resetStore = () => {
    useLogin.setState(initialStateLogin)
    usePersons.setState(initialStatePersons)
    useDictionary.setState(initialStateDictionary)
    useCompanies.setState(initialStateCompanies)
}